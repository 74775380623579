<template>
    <div v-if="state.data.data.length">
        <table v-bind="$attrs" class="table -table-striped w-full">
            <thead>
                <tr class="text-4">
                    <th v-if="selectable">
                        <label class="cb-container">
                            <input type="checkbox" class="checkbox-lg" 
                                @change="toggleSelectAll()"
                                v-model="state.selectAllChecked"
                            >
                            <span class="checkmark"></span>
                        </label>

                    </th>

                    <th v-for="column in state.data.meta.displayableColumnNames" class="px-6 pt-2">
                        <a href="#" class="flex items-center heading-6-bold text-4 sortable" @click.prevent="sortBy(column.name)">
                            {{ column.display }} 
                            <IconChevron :class="{ 
                                'arrow--asc': state.sort.key === column.name && state.sort.order === 'asc',
                                'arrow--desc': state.sort.key === column.name && state.sort.order === 'desc' }"
                            class="sort-icon w-4 ml-2"/>
                        </a>
                    </th>

                    <slot name="th" />

                </tr>
            </thead>
            <tbody>
            <template v-if="filteredRecords.length">
                <tr v-for="record in filteredRecords" :key="record[DEFAULT_KEY]" :class="{ 'clickable': clickable }" @click="handleRowClick(record)">
                    <td v-if="selectable" class="px-6 py-6">
                        <label class="cb-container">
                            <input v-if="selectableFn(record) == true" type="checkbox" class="checkbox-lg" 
                                v-model="state.selected"
                                :value="record[DEFAULT_KEY]"
                            >
                            <span class="checkmark"></span>
                        </label>
                    </td>
                    <td v-for="cname in state.data.meta.columnNames" :key="cname + '-' + record[DEFAULT_KEY]" 
                        @click="$emit('recordClick', record)">
                        <template v-for="columnValue, column in record" :key="record.uuid">
                            <template v-if="cname == column">
                                {{ columnValue }}
                            </template>
                            
                        </template>
                    </td>    

                    <slot name="td" :record="record"/>

                </tr>
            </template>

            <template v-else>
                <tr><td style="text-align: center;" :colspan="state.data.meta.columnNames.length ">No records found</td></tr>
            </template>

            <template v-for="n in (state.data.meta.per_page - filteredRecords.length)" v-if="padrows && (filteredRecords.length < state.data.meta.per_page)">
                <tr><td style="text-align: center; border: none;" :colspan="state.data.meta.columnNames.length">&nbsp;</td></tr>
            </template>

            </tbody>
        </table>
    </div>
    <div class="flex w-full justify-between p-3">
        <div>
            <slot name="bottom-start"></slot>
        </div>
        <div v-if="state.data.data.length" class="container-fluid flex justify-end px-16 py-3">
          <AppPagination
            :key="state.data.meta.per_page" 
            v-if="showPagination" 
            :meta="state.data.meta" 
            @switched="handlePageSwitch"
            @changePerPage="handlePerPageChange" />
        </div>
    </div>
</template>

<script setup>

    import { reactive, computed, onMounted, watch, toRaw, useAttrs, defineExpose } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter, useRoute } from 'vue-router'
    import _ from 'lodash'
    import axios from 'axios'

    import AppPagination from '@/views/components/common/AppPagination.vue'
    import IconChevron from '@/views/components/icons/IconChevron.vue'
    
    const attrs = useAttrs()

    const props = defineProps(['resource', 'selectable', 'selectableFn', 'clickable', 'autoloadable', 'padrows', 'defaultkey'])

    const emit = defineEmits(['changePerPage', 'onSelected'])

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const storeUnsubscribe = store.subscribeAction({
        before: (action, vuexState) => {
            //
        },
        after: (action, vuexState) => {
            console.log(action.type)
            //console.log(action.payload)
            if(action.type == 'users/bulkDelete') {
                console.log('flushing store selected ids')
                state.selected.splice(0) //flush without loosing reactivity
            }
        }
    })

    let endpoint = ''
    let resource = props.resource

    let selectable = props.selectable || false
    let selectableFn = props.selectableFn || function(record) { return true }

    let autoloadable = props.autoloadable === undefined ? true : props.autoloadable // loads record immediately when component mounted
    
    //record primary key
    let DEFAULT_KEY = props.defaultkey || 'uuid'

    let slots = props.slots

    let data = computed(() => store.getters[`${resource}/all`]);

    const state = reactive({
        selectAllChecked: false,
        selected: [],
        data: data,
        sort: {
            key: '',
            order: 'asc'
        },
        search: {
            value: '',
            operator: 'like',
            column: 'name'
        },
        quickSearchQuery: '',
        per_page: data.value.meta.per_page ? data.value.meta.per_page : 4,
        page: route.query.page ? route.query.page : 1,
        forms: {
            creating: {
                name: '',
                email: '',
                password: ''
            }
        }
    })

    watch(state.selected, async (oldSelected, newSelected) => {
        let selected = toRaw(newSelected)
        console.log(selected)

        emit('onSelected', { selected })
    })

    onMounted(() => {
        autoloadable && store.dispatch(`${resource}/all`, {
                page: state.page,
                per_page: state.per_page,
        })
    })

    const showPagination = computed(() => {
        return state.data.meta
        //return state.data.meta && filteredRecords.length && state.data.meta.last_page > 1
    })

    const filteredRecords = computed(() => {
        let data = state.data.data

        data = data.filter((row) => {
            return Object.keys(row).some((key) => {
                return String(row[key])
                    .toLowerCase()
                    .indexOf(state.quickSearchQuery.toLowerCase()) > -1
            })
        })

        // if(state.sort.key) {
        //     var parseAs = 'string'

        //     if (state.sort.key == 'id') {
        //         parseAs = 'number'
        //     }
        //     data = _.orderBy(data, (element) => {
        //         let value = element[state.sort.key]
        //         if(parseAs == 'number') {
        //             return parseFloat(value)
        //         }
        //         return String(element[state.sort.key]).toLowerCase()
        //     }, state.sort.order)
        // }

        return data
    })

    let handleRowClick = function(record) {
        if(selectable && selectableFn(record)) {
            if(state.selected.indexOf(record[DEFAULT_KEY]) > -1) {
                state.selected.splice(state.selected.indexOf(record[DEFAULT_KEY]), 1)
            } else {
                state.selected.push(record[DEFAULT_KEY])
            }
        }
    }

    let clearSearch = function() {
        state.page = 1
        state.search.value = ''

        getRecords()
    }

    let clearSelected = function() {
        //state.selected = []
        state.selected.length = 0
        state.selectAllChecked = false
    }

    let handleSearch = function() {
        if(!state.search.value.length) return
        state.page = 1

        getRecords()
    }
    
    let handleLimitChange = function() {
        state.page = 1

        router.replace({
            query: {
                per_page: state.data.meta.per_page,
                page: 1
            }
        })

        getRecords()

    }

    let handlePageSwitch = function (p) {
        //alert('page ' + p)
        state.page = p

        console.log(state)

        router.replace({
            query: {
                page: p,
                per_page: state.limit
            }
        })

        getRecords()
    }

    let handlePerPageChange = function(perPage) {
        state.page = 1
        state.per_page = perPage

        router.replace({
            query: {
                per_page: perPage,
                page: 1
            }
        })

        getRecords()

        emit('changePerPage', { perPage })
    }

    let getRecords = function() {
        console.log('getRecords')
        console.log(state.page)
        console.log(state.per_page)

        store.dispatch(`${resource}/all`, {
                page: state.page,
                per_page: state.per_page,
                order_by: state.sort.key,
                direction: state.sort.order,
                ...state.search
        })

        /*

        return axios.get(this.endpoint, {
            params: {
                limit: state.limit,
                page: state.page,
                ...state.search
            }
        }).then((response) => {
            this.response = response.data.data;
        })*/
    }

    let sortBy = function(column) {
        //alert(column)
        state.sort.key = column
        state.sort.order = state.sort.order === 'asc' ? 'desc' : 'asc'

        router.replace({
            query: {
                order_by: state.sort.key,
                direction: state.sort.order,
                page: 1
            }
        })

        getRecords()
    }
    
    let toggleSelectAll = function() {
        if(state.selectAllChecked) {
            console.log(filteredRecords.value);
            
            let records = toRaw(filteredRecords.value)
            records =  _.filter(records, (r) => selectableFn(r))
           
            let selectedIds = _.map(records, DEFAULT_KEY)  

            Object.assign(state.selected, selectedIds)
        } else {
            state.selected.length = 0    
        }
    }

    defineExpose({ clearSelected })

</script>
<style scoped>

    .sortable {
        cursor: pointer;
    }

    .sort-icon { transition: transform .1s ease-in; }

    .arrow--desc {
        transform: scaleY(-1);
    }

    .table-responsive {
        overflow-y: visible !important;
    }

    .widget-well {
        margin-bottom: 0;
        background: #fff;
        box-shadow: none;
    }

    .checkbox-lg {
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    th  {
        padding: 0 24px;
        min-width: 55px;
    }

</style>