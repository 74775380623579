<template>
   <!--<pre>{{ currentTest }} </pre>-->

    <div class="pt-8 flext justify-center text-center xl:w-3/6 mx-auto">
        <div v-if="subview == undefined || subview == '' || subview == 'methods'">
            <p class="label-soft text-1 py-2">Bitte wählen Sie die bevorzugte Methode aus</p>
            <template v-for="(method, index) in currentTest.test.methods" >
                <button @click="onMethodSelect(method)"
                    :class="{ 'active': method.id == currentTest.method_id, 'mt-3': index > 0 }"
                    class="method-button button-primary w-9/12 block mx-auto shadow-lg">{{ method.name }}</button>

            </template>
        </div>

        <div v-else-if="subview == 'properties'"> <!--or method selected-->

            <template v-for="(method, index) in currentTest.test.methods">

                <template v-if="currentTest.method_id == method.id">

                    <button v-if="currentTest.distance_type == 'far'" :class="{ 'active': currentTest.distance_type == 'far' }" class="mb-8 method-button button-primary w-9/12 block mx-auto shadow-lg">Ferne</button>
                    <button v-if="currentTest.distance_type == 'near'" :class="{ 'active': currentTest.distance_type == 'near' }"  class="mb-8 method-button button-primary mt-3 w-9/12 block mx-auto shadow-lg">Nähe</button>

                    <button class="method-button active button-primary w-9/12 block mx-auto shadow-lg">{{ method.name }}</button>
                    <div class="flex flex-col-reverse">
                        <!-- {{ localProperties }} -->
                        <div v-for="(property, index) in currentTest.properties">
                            <!--{{ localProperties[property.uuid] }}
                            {{ property.uuid }} -->
                            <div class="text-caption-upper text-1 py-1 mt-6">{{ property.property.name }}</div>
                            <select v-model="localProperties[property.uuid]" @change="selectProperty(property.uuid, $event)" class="input-select w-96 surface-1 shadow-md">
                                <option value="null" :key="idx">Keine Angabe</option>
                                <option v-for="(value, idx) in property.property.possible_values"
                                    :disabled="monokulareTest.method_option !== null &&
                                              ((monokulareTest.method_option == 1 && value <= 5) || (monokulareTest.method_option == 2 && value > 5))"
                                    :value="value"
                                    :key="idx"
                                    >{{ property.property.labels[idx] }}</option>
                            </select>

                            <div v-if="property.info_text" class="mt-1 text-caption-upper text-5 ">{{ property.info_text }}</div>

                        </div>

                    </div>
                </template>

            </template>

            <button v-if="currentTest.saved" @click="resetSaved()" class="mt-4 label-underline-sm text-1">Standard zurücksetzen</button>
            <button v-else="currentTest.saved" @click="resetTest()" class="mt-4 text-p-3-underline text-1">Zurück zu Methodenauswahl</button>

        </div>

    </div>


    <template v-if="!subview || subview == 'methods'">
        <div v-if="currentTest.test.info_text_title" class="documentation-info-panel w-full max-w-6xl mt-4 px-3 py-10 text-white">
            <div class="documentation-inner  max-h-96 overflow-y-auto px-3">

                <h4 class="heading-4-bold-s text-light flex items-center"><IconPdfBig class="w-8 mr-2"/> {{ currentTest.test.info_text_title }}</h4>

                <p v-html="currentTest.test.info_text_content" class="pl-1"></p>

                <a :href="currentTest.test.info_text_url" target="_blank" class="doc-link-btn mt-6 inlie-block bg-white rounded">Herunterladen</a>

            </div>
        </div>
    </template>
    <template v-if="subview == 'properties'">
        <div v-if="currentMethod.info_text_title" class="documentation-info-panel w-full max-w-6xl mt-4 px-3 py-10 text-white">
            <div class="documentation-inner  max-h-96 overflow-y-auto px-3">

                <h4 class="heading-4-bold-s text-light flex items-center"><IconPdfBig class="w-8 mr-2"/> {{ currentMethod.info_text_title }}</h4>

                <p v-html="currentMethod.info_text_content" class="pl-1"></p>

                <a :href="currentMethod.info_text_url" target="_blank" class="doc-link-btn mt-6 inline-block bg-white rounded">Herunterladen</a>

            </div>
        </div>
    </template>

    <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="methodConfirmationModal">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">
            <div class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10">Als Standard speichern</h1>
                <p class="w-96 mx-auto mt-7 leading-4">
                    Möchten Sie die ausgewählte Methode als Standard<br>
                    speichern, um in der Zukunft mit der Sequenz<br>
                    schneller voranzukommen? Mit dem Button<br>
                    “Standard zurücksetzen” können Sie wieder die<br>
                    gespeicherte Einstellung überschreiben
                </p>
                <div class="flex items-center justify-between mt-7">
                    <button @click="confirmMethod(true)" class="button-primary w-7/12 mx-auto">
                        Auswahl als Standard speichern und fortfahren
                    </button>
                </div>
                <div class="flex items-center justify-between mt-3">
                    <button @click="confirmMethod(false)" class="button-primary-outlined  w-7/12 mx-auto">
                        Auswahl nicht speichern und fortfahren
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>

</template>

<script setup>

    import { useStore } from 'vuex'
    import { ref, toRaw, reactive, computed, onMounted, markRaw } from 'vue'
    import { useRouter, useRoute } from 'vue-router'

    import AppConfirmModal from '@/views/components/common/AppConfirmModal.vue'
    import IconPdfBig from '@/views/components/icons/IconPdfBig.vue'

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const props = defineProps({
        'test': Object
    })

    let currentMethod = computed(() => {
        let methods = currentTest.value.test.methods.filter((m) => m.id == currentTest.value.method_id)
        console.log(toRaw(methods))

        return methods[0]
    })

    let monokulareTest = computed(() => {

        let getByName = store.getters[`tests/getByName`]

        return getByName('Monokulare Akkommodations-Flexibilität ±2 dpt Flipper in Zyklen/Minute')
    })

    const patientId = route.params.patientId

    const treatmentId = route.params.treatmentId

    const test = route.params.test

    const subview = route.params.subview || ''

    let testLabel = 'Akkommodationsbreite monokular'

    let currentTest = computed(() => props.test)

    let localProperties = ref({})

    const methodConfirmationModal = ref()

    let selectedMethod = ref()

    let selectType = async function(type) {

      let t = toRaw(currentTest.value)

      console.log(t)

      await store.dispatch(`tests/updateTest`, {
          patientId,
          treatmentId,
          testId: t.uuid,
          data: {
              distance_type: type,
          }
      })

      router.push({
          name: 'test-sequence-control',
          params: {
              ...route.params,
              patientId,
              treatmentId,
              test: test,
              subview: 'methods'
          }
      })
    }

    let onMethodSelect = function(method) {
        selectedMethod.value = method

        methodConfirmationModal.value.show()
    }

    let confirmMethod = function(remember) {

        if(!selectedMethod.value) return

        selectMethod(selectedMethod.value, remember)

        methodConfirmationModal.value.close()

        selectedMethod.value = null
    }

    let selectMethod = async function(method, remember = false) {

      let t = toRaw(currentTest.value)

      await store.dispatch(`tests/updateTest`, {
          patientId,
          treatmentId,
          testId: t.uuid,
          data: {
              method_id: method.id,
              distance_type: t.distance_type,
              remember
          }
      })

      router.push({
          name: 'test-sequence-control',
          params: {
              ...route.params,
              patientId,
              treatmentId,
              test: test,
              subview: 'properties'
          }
      })

    }

    let selectProperty = async function(propertyUuid, event) {

       let t = toRaw(currentTest.value)

       let value = event.target.value == 'null' ? null : event.target.value

       await store.dispatch(`tests/updateTestPoperty`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           propertyId: propertyUuid,
           data: {
               value
           }
       })

       router.push({
           name: 'test-sequence-control',
           params: {
               ...route.params,
               patientId,
               treatmentId,
               test: test,
               subview: 'properties'
           }
       })
    }

    let resetTest = function() {
        router.push({
            name: 'test-sequence-control',
            params: {
                ...route.params,
                patientId,
                treatmentId,
                test: test,
                subview: 'methods'
            }
        })
    }

    let resetSaved = async function() {
        let t = toRaw(currentTest.value)

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: null,
                distance_type: t.distance_type,
                remember: false
            }
        })

        router.push({
            name: 'test-sequence-control',
            params: {
                ...route.params,
                patientId,
                treatmentId,
                test: test,
                subview: ''
            }
        })
    }

    let switchToPropertiesView = function() {
        router.push({
            name: 'test-sequence-control',
            params: {
                ...route.params,
                patientId,
                treatmentId,
                test: test,
                subview: 'properties'
            }
        })
    }

    onMounted(async () => {
        console.log(treatmentId)
        console.log('Akkommodationsbreite COMPONENT')
        console.log( route.params)

        //alert(subview)

        store.dispatch(`app/setWizardDisabled`, false)

        await store.dispatch(`tests/setCurrentTitle`, testLabel)

        currentTest.value.properties.forEach((p) => {
            if (p.property.nullable == 1 && p.value === null) {
                localProperties.value[p.uuid] = 'null'
            } else {
                localProperties.value[p.uuid] = p.value === null ? p.default_value : p.value
            }
        })

        if(currentTest.value.saved == true && subview == '') {
            switchToPropertiesView()
        }
    })

</script>
<style scoped>
    * {
        scrollbar-width: thin;
        scrollbar-color: var(--color-primary-800);
    }

    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 4px;
    }

    *::-webkit-scrollbar-track {
        background: var(--color-shade-0);
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--color-shade-1) !important;
        border-radius: 20px;
        border: 0px;
    }

   .method-button.active {
       background: var(--color-primary-700);
   }

   .input-select {
       border: 1px solid var(--color-primary-500);
       padding: 8px 12px;
       border-radius: var(--radius-sm);
       min-width: 120px;
   }
</style>

