import useAuth from '@/api/useAuth'

const { login,
    login2FA,
    logout,
    fetchUser,
    registerUser,
    resetPassword,
    storeNewPassword,
    changePassword,
    saveViewSettings } = useAuth()

export default {
    namespaced: true,

    state () {
        return {
            user: null,
            authenticated: false,
            isLoading: false,
        }
    },

    getters: {
        user (state) {
            return state.user 
        },

        authenticated (state) {
            return state.authenticated
        },

        isLoading (state) {
            return state.isLoading
        }
    },

    actions: {
        async login ({commit}, credentials) {
            //console.log(credentials);
            commit('SET_LOADING', true)

            let data = await login(credentials).catch((err) => {
                commit('SET_LOADING', false)

                commit('SET_USER', null)
                commit('SET_AUTHENTICATED', false)
                
                return Promise.reject(err);
            })

            commit('SET_LOADING', false)

            //2FA
            if (data.two_auth_required === true) {

                return Promise.resolve(data)

            } else {

                let user = data

                commit('SET_USER', user)
                commit('SET_AUTHENTICATED', true)
    
                return Promise.resolve(user)
            }
        },

        async login2FA ({commit}, credentials) {
            commit('SET_LOADING', true)

            let user = await login2FA(credentials).catch((err) => {
                commit('SET_LOADING', false)

                commit('SET_USER', null)
                commit('SET_AUTHENTICATED', false)
                
                return Promise.reject(err);
            })

            commit('SET_LOADING', false)

            commit('SET_USER', user)
            commit('SET_AUTHENTICATED', true)

            return Promise.resolve(user)
        },

        async logout({commit}) {

            await logout()

            commit('SET_USER', null)
            commit('SET_AUTHENTICATED', false)

        },

        async user({commit}) {
            let user = await fetchUser().catch((err) => {
                return Promise.reject(err)
            })

            if(user) {
                commit('SET_USER', user)
                commit('SET_AUTHENTICATED', true)
            } else {
                commit('SET_USER', null)
                commit('SET_AUTHENTICATED', false)
            }

        },

        async register({commit}, payload) {

            commit('SET_LOADING', true)

            let user = await registerUser(payload).catch((err) => {
                commit('SET_LOADING', false)

                return Promise.reject(err)
            })

            commit('SET_LOADING', false)

            console.log('ok')
            console.log(user)
            return Promise.resolve(user);
        },

        async requestPasswordReset({commit}, payload) {
            commit('SET_LOADING', true)

            let resp = await resetPassword(payload).catch((err) => {
                commit('SET_LOADING', false)

                return Promise.reject(err)
            })

            commit('SET_LOADING', false)

            console.log('ok')
            console.log(resp)
            return Promise.resolve(resp);
        },

        async storeNewPassword({commit}, payload) {
            let resp = await storeNewPassword(payload).catch((err) => {
                return Promise.reject(err)
            })
            console.log('ok')
            console.log(resp)
            return Promise.resolve(resp);
        },

        async changePassword({commit}, payload) {
            let resp = await changePassword(payload).catch((err) => {
                return Promise.reject(err)
            })
            console.log('ok')
            console.log(resp)
            return Promise.resolve(resp);
        },

        async saveViewSettings({commit}, payload) {
            let resp = await saveViewSettings(payload).catch((err) => {
                return Promise.reject(err)
            })
            console.log('ok')
            console.log(resp)
            return Promise.resolve(resp);
        }
    },

    mutations: {
        SET_USER (state, user) {
            state.user = user
        },

        SET_AUTHENTICATED (state, authenticated) {
            state.authenticated = authenticated
        },

        SET_LOADING (state, isLoading) {
            state.isLoading = isLoading
        }
    }
}