<template>
   <!--<pre>{{ currentTest }} </pre> -->

   <div :class="{ 'test-panel-forbidden': isForbidden }" class="test-panel pt-2 text-center w-full xl:w-3/4">

    <div class="test-item rounded"> <!--or method selected-->    
        <p class="subheading-bold text-1 py-2 text-left">Konvergenz-Nahpunkt mit Rotglas</p>

        <template v-for="(method, index) in currentTest.test.methods">
                
            <template v-if="currentTest.method_id == method.id">
                <div class="flex mr-3">
                    <!-- {{ localProperties }} -->
                    <div v-for="(property, index) in currentTest.properties" :class="{ 'ml-6': index > 0 }" class="flex-col text-left">
                        <!--{{ localProperties[property.uuid] }}
                        {{ property.uuid }} -->
                        <div class="text-caption-upper text-left text-1 py-2 mt-2">{{ property.property.name }}</div>
                        <select v-model="localProperties[property.uuid]" @change="selectProperty(property.uuid, $event)" class="input-select w-full xl:w-60 mr-6 surface-1 shadow-md">
                            <option value="null" :key="idx">Keine Angabe</option>
                            <option v-for="(value, idx) in property.property.possible_values" :value="value" :key="idx">{{ property.property.labels[idx] }}</option>
                        </select>

                        <div v-if="property.info_text" class="mt-1 text-caption-upper text-5">{{ property.info_text }}</div>

                    </div>
                
                </div>
            </template>

        </template>
 
    </div>
   
   </div>
 
</template>

<script setup>

    import { useStore } from 'vuex'
    import { ref, toRaw, reactive, computed, onMounted, markRaw } from 'vue'
    import { useRouter, useRoute } from 'vue-router' 
    
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const props = defineProps({
        'test': Object
    })

    let localProperties = ref({})
    
    const patientId = route.params.patientId
    
    const treatmentId = route.params.treatmentId

    const test = route.params.test

    const subview = route.params.subview || ''


    let currentTest = computed(() => props.test)

    let selectType = async function(type) {

      let t = toRaw(currentTest.value)

      console.log(t)

      await store.dispatch(`tests/updateTest`, {
          patientId,
          treatmentId,
          testId: t.uuid,
          data: {
              distance_type: type,
          }
      })
    
    //   router.push({ 
    //       name: 'test-sequence-control',
    //       params: {
    //           ...route.params,
    //           patientId,
    //           treatmentId,
    //           test: test,
    //           subview: 'methods'
    //       }
    //   })
    }

    let selectMethod = async function(method) {
      
      let t = toRaw(currentTest.value)

      await store.dispatch(`tests/updateTest`, {
          patientId,
          treatmentId,
          testId: t.uuid,
          data: {
              method_id: method.id,
              distance_type: t.distance_type,
          }
      })

    //   router.push({ 
    //       name: 'test-sequence-control',
    //       params: {
    //           ...route.params,
    //           patientId,
    //           treatmentId,
    //           test: test,
    //           subview: 'properties'
    //       }
    //   })
        reloadProperties()

    }
    
    let selectProperty = async function(propertyUuid, event) {
       
       let t = toRaw(currentTest.value)

       let value = event.target.value == 'null' ? null : event.target.value

       await store.dispatch(`tests/updateTestPoperty`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           propertyId: propertyUuid,
           data: {
               value
           }
       })

    //    router.push({ 
    //        name: 'test-sequence-control',
    //        params: {
    //            ...route.params,
    //            patientId,
    //            treatmentId,
    //            test: test,
    //            subview: 'properties'
    //        }
    //    })

    }
    
    let reloadProperties = function () {
        currentTest.value.properties.forEach((p) => { 
            if (p.property.nullable == 1 && p.value === null) {
                localProperties.value[p.uuid] = 'null'
            } else {
                localProperties.value[p.uuid] = p.value === null ? p.default_value : p.value
            }
        })
    }
    
    let strabismusQuestions = computed(() => store.getters['strabismusquestions/all'])

    let isForbidden = computed(() => {
        
        let forbidden = false

        if (strabismusQuestions.value.length) {
            forbidden = strabismusQuestions.value[0]['forbidden_answers'].indexOf(strabismusQuestions.value[0]['answer']) > -1
        }

        return forbidden
    })

    onMounted(async () => {
       console.log(treatmentId)
       console.log('Konvergenznahpunkt COMPONENT FAST')
       console.log( route.params)

       //alert(subview)

       await store.dispatch(`tests/setCurrentTitle`, 'Konvergenz-Nahpunkt mit Rotglas')
       
       reloadProperties()
  
    })
   
</script>
<style scoped>

   .method-button.active {
       background: var(--color-primary-700);
   }

   .input-select {
       border: 1px solid var(--color-primary-500);
       padding: 8px 12px;
       border-radius: var(--radius-sm);
       min-width: 120px;
   }
</style>

