<template>
    <div class="pagination flex items-center">
        <div class="flex items-center">
            <span>Zeilen pro Seite:</span>
            <select @change="$emit('changePerPage', perPage)" v-model="perPage">
                <option value="4">4</option>
                <option value="8">8</option>
                <option value="16">16</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </div>
        <div class="pagination-controls flex items-center ml-5">
            <span class="mx-2">{{ meta.from }} - {{ meta.to }}  von {{ meta.total }}</span>
            <a @click.prevent="switched(meta.current_page - 1)" href="#prev" class="ml-2 px-2" title="Previous"><IconChevronLeft/></a>
            <a @click.prevent="switched(meta.current_page + 1)" href="#next" class="ml-2 px-2" title="Next"><IconChevronRight/></a>
        </div>
    </div>
    <!--<span>{{ props.meta }}</span>-->
</template>

<script setup>

import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import IconChevronLeft from '@/views/components/icons/IconChevronLeft.vue'
import IconChevronRight from '@/views/components/icons/IconChevronRight.vue'

const props = defineProps(['meta'])

const emit = defineEmits(['switched'])

let perPage = ref('')

let switched = function (p) {
    if(outOfBounds(p)) return
    emit('switched', p)
}

let outOfBounds= function (p) {
    return p <= 0 || p > props.meta.last_page
}

onMounted(() => {
    console.log(props.meta)
    perPage.value = props.meta.per_page
})
</script>
<style scoped>
    .pagination select {
        background: transparent;
        width: 46px;
        text-align: center;
    }
    .pagination span { color: var(--color-shade-dark-mute); }
    .pagination-controls {
        color: var(--color-shade-dark-mute);
    }
</style>