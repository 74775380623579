

<template>
    <!--
    <pre>{{ localPropertiesDelayed }} </pre> 
    <pre>{{ localProperties }} </pre> 
    <pre>{{ currentTest.properties[0].property.name + ' z_value: ' + currentTest.properties[0].z_value }}</pre> 
    <pre>{{ currentTest.properties[1].property.name + ' z_value: ' + currentTest.properties[1].z_value }}</pre> 
    -->
   <div class="pt-8 flext justify-center text-center xl:w-3/6 mx-auto">

    <div v-if="subview == undefined || subview == '' || subview == 'properties'"> <!--or method selected-->    
        <p class="label-soft text-1 py-2">Bitte wählen Sie für beide Eingabefelder die passenden Werte aus</p>

        <!--<pre>{{ currentMethodOption }} </pre> -->

        <div v-if="currentTest.method.options" class="flex items-center justify-center">
            <template v-for="(option, index) in currentTest.method.options">

                <label @click.prevent="selectMethodOption(index + 1)" class="text-p-3-bold text-primary-700 flex items-center cursor-pointer leading-none mr-3">
                    <input v-model="currentMethodOption" value="none" type="radio" class="hidden cursor-pointer">
                    <IconRadioChecked v-if="currentMethodOption - 1 == index" />
                    <IconRadio v-else />
                    <span class="mt-1 ml-1">{{ option }}</span>
                </label>
            </template>
        </div>
       

        <template v-for="(method, index) in currentTest.test.methods">
                
            <template v-if="currentTest.method_id == method.id">
                <div :class="{ 'opacity-25 cursor-not-allowed': currentMethodOption == 2 }" class="mt-3">
                    <!-- {{ localProperties }} -->
                    <div v-for="(property, index) in currentTest.properties">
                        <!--{{ localProperties[property.uuid] }}
                        {{ property.uuid }} -->
                        <div class="text-caption-upper text-1 py-1 mt-6">{{ property.property.name }}</div>
                        <select v-model="localProperties[property.uuid]" 
                            @change="selectProperty(property.uuid, $event)" 
                            class="input-select w-96 surface-1 shadow-md" 
                            :disabled="currentMethodOption == 2">
                            <option value="null" :key="idx">Keine Angabe</option>
                            <option v-for="(value, idx) in property.property.possible_values" 
                                :value="value" 
                                :key="idx">{{ property.property.labels[idx] }}</option>
                        </select>
                        <div v-if="property.property.has_delayed_selection == 1" class="flex flex-col">
                            <!--<div class="text-caption-upper text-5 py-1 mt-2 text-left">Please use input fields placeholder</div>-->
                            <div class="flex items-center justify-between mt-2 mx-20 ">
                                <label @click.prevent="selectDelayedProperty(property.uuid, 'none')" class="text-p-3-bold text-primary-700 flex items-center cursor-pointer leading-none">
                                    <input v-model="localPropertiesDelayed[property.uuid]" 
                                        value="none" 
                                        type="radio" 
                                        class="hidden cursor-pointer"
                                        :disabled="currentMethodOption == 2">
                                    <IconRadioChecked v-if="localPropertiesDelayed[property.uuid] == 'none' || !localPropertiesDelayed[property.uuid]" />
                                    <IconRadio v-else />
                                    <span class="mt-1 ml-1 mr-3">Keine Verzögerung</span>
                                </label>
                                <label @click.prevent="selectDelayedProperty(property.uuid, 'plus')" class="text-p-3-bold text-primary-700 flex items-center cursor-pointer leading-none">
                                    <input v-model="localPropertiesDelayed[property.uuid]" 
                                        value="plus" 
                                        type="radio" 
                                        class="hidden cursor-pointer"
                                        :disabled="currentMethodOption == 2">
                                    <IconRadioChecked v-if="localPropertiesDelayed[property.uuid] == 'plus'" />
                                    <IconRadio v-else />
                                    <span class="mt-1 ml-1 mr-3">Plus verzögert</span>
                                </label>
                                <label @click.prevent="selectDelayedProperty(property.uuid, 'minus')" class="text-p-3-bold text-primary-700 flex items-center cursor-pointer leading-none ml-3">
                                    <input v-model="localPropertiesDelayed[property.uuid]" 
                                        value="minus" 
                                        type="radio" 
                                        class="hidden cursor-pointer"
                                        :disabled="currentMethodOption == 2">
                                    <IconRadioChecked v-if="localPropertiesDelayed[property.uuid] == 'minus'" />
                                    <IconRadio v-else />
                                    <span class="mt-1 ml-1 mr-3">Minus verzögert</span>
                                </label>
                                <label @click.prevent="selectDelayedProperty(property.uuid, 'plus-minus')" class="text-p-3-bold text-primary-700 flex items-center cursor-pointer leading-none">
                                    <input v-model="localPropertiesDelayed[property.uuid]" 
                                        value="plus-minus" 
                                        type="radio" 
                                        class="hidden cursor-pointer" 
                                        :disabled="currentMethodOption == 2">
                                    <IconRadioChecked v-if="localPropertiesDelayed[property.uuid] == 'plus-minus'" />
                                    <IconRadio v-else />
                                    <span class="mt-1 ml-1">Plus und Minus verzögert</span>
                                </label>
                            </div>
                            
                            <div v-if="property.info_text" class="mt-1 text-caption-upper text-5 ">{{ property.info_text }}</div>

                        </div>

                    </div>
                
                </div>
            </template>

        </template>
 
    </div>
   
   </div>

   <template v-if="!subview || subview == 'properties'">
        <div v-if="currentTest.test.info_text_title" class="documentation-info-panel w-full max-w-6xl mt-4 px-3 py-10 text-white">
            <div class="documentation-inner  max-h-96 overflow-y-auto px-3">

                <h4 class="heading-4-bold-s text-light flex items-center"><IconPdfBig class="w-8 mr-2"/> {{ currentTest.test.info_text_title }}</h4>

                <p v-html="currentTest.test.info_text_content" class="pl-1"></p>

                <a :href="currentTest.test.info_text_url" target="_blank" class="doc-link-btn mt-6 inline-block bg-white rounded">Herunterladen</a>

            </div>
        </div>
    </template>
 
</template>

<script setup>

    import { useStore } from 'vuex'
    import { ref, toRaw, reactive, computed, onMounted, markRaw } from 'vue'
    import { useRouter, useRoute } from 'vue-router' 

    import IconRadio from '@/views/components/icons/IconRadio.vue'
    import IconRadioChecked from '@/views/components/icons/IconRadioChecked.vue'
    import IconPdfBig from '@/views/components/icons/IconPdfBig.vue'

    
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const props = defineProps({
        'test': Object
    })

    let currentMethod = computed(() => {
        let methods = currentTest.value.test.methods.filter((m) => m.id == currentTest.value.method_id)
        console.log(toRaw(methods))

        return methods[0]
    })

    let currentMethodOption = ref(null) // default null


    let localProperties = ref({})
    let localPropertiesDelayed = ref({})

    const patientId = route.params.patientId
    
    const treatmentId = route.params.treatmentId

    const test = route.params.test

    const subview = route.params.subview || ''


    let currentTest = computed(() => props.test)

    let Z_VALUE_TRESHOLD = -1

    let selectMethodOption = async function(option) {
        let t = toRaw(currentTest.value)
        console.log(t)

        currentMethodOption.value = option

        await store.dispatch(`tests/updateTest`, {
            patientId,
            treatmentId,
            testId: t.uuid,
            data: {
                method_id: t.method.id,
                method_option: '' + currentMethodOption.value
            }
        })

        checkWizardEnabled()
    }

    let selectType = async function(type) {

      let t = toRaw(currentTest.value)

      console.log(t)

      await store.dispatch(`tests/updateTest`, {
          patientId,
          treatmentId,
          testId: t.uuid,
          data: {
              distance_type: type,
          }
      })
    
      router.push({ 
          name: 'test-sequence-control',
          params: {
              ...route.params,
              patientId,
              treatmentId,
              test: test,
              subview: 'methods'
          }
      })
    }

    let selectMethod = async function(method) {
      
      let t = toRaw(currentTest.value)

      await store.dispatch(`tests/updateTest`, {
          patientId,
          treatmentId,
          testId: t.uuid,
          data: {
              method_id: method.id,
              distance_type: t.distance_type,
          }
      })

      router.push({ 
          name: 'test-sequence-control',
          params: {
              ...route.params,
              patientId,
              treatmentId,
              test: test,
              subview: 'properties'
          }
      })

    }
    
    let selectProperty = async function(propertyUuid, event) {
       let t = toRaw(currentTest.value)

       let value = event.target.value == 'null' ? null : event.target.value

       let delayed = localPropertiesDelayed.value[propertyUuid] ? localPropertiesDelayed.value[propertyUuid] : 'none'

       await store.dispatch(`tests/updateTestPoperty`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           propertyId: propertyUuid,
           data: {
               value,
               delayed
           }
       })

       router.push({ 
           name: 'test-sequence-control',
           params: {
               ...route.params,
               patientId,
               treatmentId,
               test: test,
               subview: 'properties'
           }
       })

       checkWizardEnabled()

    }

    let selectDelayedProperty = async function(propertyUuid, delayed) {
        checkWizardEnabled()

        if (currentMethodOption.value == 2) {
            return
        }

        let t = toRaw(currentTest.value)

        let value = localProperties.value[propertyUuid]

        value = value == 'null' ? null : value


        localPropertiesDelayed.value[propertyUuid] = delayed ? delayed : 'none'
        
        checkWizardEnabled()

        await store.dispatch(`tests/updateTestPoperty`, {
           patientId,
           treatmentId,
           testId: t.uuid,
           propertyId: propertyUuid,
           data: {
               value,
               delayed
           }
       })

       router.push({ 
           name: 'test-sequence-control',
           params: {
               ...route.params,
               patientId,
               treatmentId,
               test: test,
               subview: 'properties'
           }
       })


    }

    let checkWizardEnabled = function() {

        let isWizardDisabled = false
    
        if (currentMethodOption.value === null) {
            isWizardDisabled = true
        } else if (currentMethodOption.value == 1) { // Akk.-Breite > 5.5 dpt
        
            let t = toRaw(currentTest.value)

            if (t.method_id === null ) { 
                isWizardDisabled = true
            }

            for (const key in localProperties.value) {

                if(localProperties.value[key] == 'null') {
                    isWizardDisabled = true
                }
            }
  
            for (const property of t.properties) {
                let z = Number(property.z_value)
                console.log( property.property.name + ':  ' + localPropertiesDelayed.value[property.uuid] + '   z_value:  ' + z)

                if (localPropertiesDelayed.value[property.uuid] == 'none' && z < Z_VALUE_TRESHOLD) {
                    console.log(' z_value ' + property.property.name + ' ' + z + ' < ' + Z_VALUE_TRESHOLD)

                    isWizardDisabled = true
                }
            }
            
        }

        store.dispatch(`app/setWizardDisabled`, isWizardDisabled)

    }

    onMounted(async () => {
       console.log(treatmentId)
       console.log('Monokulare Akkommodations COMPONENT')
       console.log( route.params)

       //alert(subview)

       await store.dispatch(`tests/setCurrentTitle`, 'Monokulare Akkommodations-Flexibilität ±2 dpt Flipper in Zyklen/Minute *')
      
       currentTest.value.properties.forEach((p) => { 

            if (p.property.nullable == 1 && p.value === null) {
                localProperties.value[p.uuid] = 'null'
            } else {

                localProperties.value[p.uuid] = p.value || p.default_value                
            }

            if(p.property.has_delayed_selection == 1) {
                localPropertiesDelayed.value[p.uuid] = p.delayed 
            }

        })

        currentMethodOption.value = currentTest.value.method_option !== null ? currentTest.value.method_option : currentMethodOption.value

        checkWizardEnabled()
    })
   
</script>
<style scoped>

    * {
        scrollbar-width: thin;
        scrollbar-color: var(--color-primary-800);
    }

    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 4px;
    }

    *::-webkit-scrollbar-track {
        background: var(--color-shade-0);
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--color-shade-1) !important;
        border-radius: 20px;
        border: 0px;
    }

   .method-button.active {
       background: var(--color-primary-700);
   }

   .input-select {
       border: 1px solid var(--color-primary-500);
       padding: 8px 12px;
       border-radius: var(--radius-sm);
       min-width: 120px;
   }

   label svg { flex-shrink: 0; }
</style>

