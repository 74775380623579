<template>
    <div class="login-panel surface-brand-2 w-full grid place-items-center h-screen">

        <div class="w-1/4 mx-auto surface-2 shadow-1 rounded px-8 pt-6 pb-8 mb-4" style="min-width: 416px;">

            <div class="flex items-center flex-col">
              <!--<img alt="BTSO logo" class="logo heading-4-medium-s" src="@/assets/logo-main.svg" />-->
                <BtsoLogo class="w-32" />
            </div>
            <p class="heading-5-medium text-center my-6">Reset Passwort</p>
            <p class="italic text-center">{{ form.email }}</p>

            <form class="pt-5" v-on:submit.prevent="save()">
                <input v-model="form.email" type="hidden">
                <input v-model="form.token" type="hidden">
                <div :class="{ 'has-error': errors.password[0] }" class="mb-7">
                    <label class="input-label-lg text-1 mb-2 uppercase" for="password">
                        Passwort
                    </label>
                    <input v-model="form.password" @focus="reset('password')" class="input border rounded w-full" id="password" type="password" placeholder="Bitte geben Sie hier Ihr Passwort an">
                    <span class="feedback" v-if="errors.password[0]">{{ errors.password[0] }}</span>
                </div>

                <div :class="{ 'has-error': errors.password_confirmation[0] }">
                    <label class="input-label-lg text-1 mb-2 uppercase" for="password_confirmation">
                        Passwort repeat
                    </label>
                    <input v-model="form.password_confirmation" @focus="reset('password_confirmation')" class="input border rounded w-full" id="password_confirmation" type="password" placeholder="Bitte geben Sie hier Ihr Passwort an">
                    <span class="feedback" v-if="errors.password_confirmation[0]">{{ errors.password_confirmation[0] }}</span>
                </div>
                <div v-if="msg" class="text-danger">{{ msg }}</div>
                <div class="flex items-center justify-between mt-7">
                    <button class="button-primary w-full" type="submit">
                        Save
                    </button>
                </div>

            </form>
        </div>
    </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { ref, toRaw, reactive, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import BtsoLogo from '@/views/components/icons/BtsoLogo.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()
const toast = useToast()

const msg = ref('')

const form = reactive({
    token: '',
    email: '',
    password: '',
    password_confirmation: '',
})

let errors = reactive({
    token: [],
    email:  [],
    password: [],
    password_confirmation: [],
})

onMounted(() => {
    console.log(route.query)

    form.token = route.query.token
    form.email = route.query.email

    store.dispatch('app/showMenu', false)
})

const reset = (field) => {
    console.log(field)
    errors[field] = [];

    msg.value = ''
}

const save = async() => {
    let payload = toRaw(form)

    let resp = await store.dispatch('auth/storeNewPassword', payload).catch((err) => {
        if(err.errors) {
            Object.assign(errors, err.errors)

        }
        msg.value = err.message
    })

    console.log(resp)

    if (resp) {
        toast.default('Passwort geändert', { position: 'top-right' })

        router.push({ name: 'login' })
    }
}

</script>