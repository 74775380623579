<template>
    <div :class="{ 'flex-1 surface-4 p-3': !compact }" class="flex">

        <div :class="{ 'surface-2 rounded': !compact }" class="flex-1 p-3">
            <h1 v-if="!compact" class="heading-3-medium text-1">Anamnese</h1>
            <h1 v-else class="heading-4-medium text-8">Anamnese</h1>

            <div class="h-1 hr-divider mb-4"></div>
            <!-- {{answersValid ? ' answersValid true' : 'answersValid false' }} -->
            <!-- {{ isWizardDisabled ? 'disabled' : ' enabled'}} -->

            <!-- {{questionsWithAnswers.length }}
             {{questionsAnswered.length }}-->

            <div v-if="questions" :class="{ 'test-wrapper': !compact }" class=" rounded">
                <div v-show="!compact" class="pt-3 pb-6">
                    <button @click="skipAnamnese()" :disabled="isSkipAnamneseDisabled" :class="{ 'opacity-50': isSkipAnamneseDisabled }" class="button-sm button-warning flex items-center text-1 px-4 py-2 radius-sm leading-4" type="submit">
                        Anamnese überspringen
                        <IconChevronRight class="ml-3 h-2" />
                    </button>
                </div>
                <div v-for="(question, index) in questions" :key="question.uuid" :class="{ 'mt-6': question.question_index > 1 }" class="test-item rounded-lg">

                    <h4 class="subheading-bold text-1">{{ question.question }} {{ index == 0 ? '*' : '' }}</h4>
                    <div v-if="question.possible_answers.length" class="answers ---mt-3 -ml-3">
                        <template v-for="(answer, answer_index) in question.possible_answers" :key="answer">
                            <label @click.prevent="handleQuestionAnswer(question.uuid, answer)" :class="{ 'active': answer == question.answer }" class="button-primary --active button-sm ml-3 px-5 py-2 radius-sm">
                                {{ answer }}
                            </label>
               
                            <AppTooltip class="ml-3" 
                                v-if="answer == question.answer 
                                    && question.possible_answer_info_buttons.length > 0 
                                    && question.possible_answer_info_buttons[answer_index]" 
                                    :trigger-type="hover" 
                                    position="right" 
                                    :opened="true">
                                <template v-slot:trigger><IconInfo class="inline-flex" /></template>
                                <template v-slot:content><div class="text-left">{{ question.possible_answer_info_buttons[answer_index] }}</div></template>
                            </AppTooltip>   
                        </template>       
                    </div>
                    <!--{{ question.possible_answers.indexOf(question.answer) }} -->
                    <div v-if="question.answer && question.possible_additional_answers.length > 1" 
                         v-show="question.possible_answers.indexOf(question.answer) == index" 
                         v-for="(possible_additional_answers, index) in question.possible_additional_answers" 
                         :key="possible_additional_answers" :class="{ '__mt-6': question.answer && question.possible_additional_answers[index].length > 1 }" class="-ml-3 flex flex-wrap additional-answers">
                        <!--{{ index }} -->
                        <template v-if="possible_additional_answers.length > 1" v-for="(additional_answer, jndex) in possible_additional_answers" :key="additional_answer">
                            
                             <label v-if="question.additional_answers_selection == 'multiple'" 
                                    @click.prevent="handleQuestionAdditionalAnswer(question.uuid, question.answer, Math.pow(2, jndex), (jndex == question.allows_custom_additional_answer_index) && question.additional_answer & Math.pow(2, jndex))" 
                                    class="button-primary-outlined button-sm text-primary ml-3 px-5 py-2 radius-sm" >
                                <IconCheckboxChecked v-if="question.additional_answer & Math.pow(2, jndex)" class="inline mr-2"/>
                                <IconCheckbox v-else class="inline mr-2"/>{{ additional_answer }}
                                 <input type="checkbox" class="hidden" name="problem-1-1" value="true">
                                 <!--{{ Math.pow(2, jndex) }}-->
                             </label>
                             
                             <label v-else 
                                 @click.prevent="handleQuestionAdditionalAnswer(question.uuid, question.answer, Math.pow(2, jndex), jndex == question.allows_custom_additional_answer_index)" :class="{ 'active': question.additional_answer & Math.pow(2, jndex) }" class="button-primary-outlined button-sm text-primary --active ml-3 px-5 py-2 radius-sm">
                                {{ additional_answer }}
                                <input type="checkbox" class="hidden" name="problem-1-1" value="true">
                            </label> 
                            
                            <AppTooltip class="ml-3 mr-4" 
                                v-if="question.possible_additional_answer_info_buttons[index] && 
                                question.possible_additional_answer_info_buttons[index][jndex] &&
                                      question.possible_additional_answer_info_buttons[index][jndex].length > 0" 
                                    :trigger-type="hover"
                                    :opened="false" 
                                    position="right">
                                <template v-slot:trigger><IconInfo class="inline-flex" /></template>
                                <template v-slot:content><div class="text-left">{{ question.possible_additional_answer_info_buttons[index][jndex] }}</div></template>
                            </AppTooltip>   
                            
                        </template>


                        <template v-if="question.allows_custom_additional_answer">
            
                            <input 
                                v-show="question.additional_answer & Math.pow(2, question.allows_custom_additional_answer_index)" 
                                :value="question.custom_additional_answer" 
                                @keyup="handleQuestionCustomAdditionalAnswer($event)" 
                                @input="handleQuestionCustomAdditionalAnswer($event)" 
                                @focus="handleQuestionCustomAdditionalAnswerEdit(question.uuid, question.answer, Math.pow(2, question.allows_custom_additional_answer_index))" 
                                @blur="handleQuestionCustomAdditionalAnswerBlur($event)"
                                type="text"
                                class="py-1 h-9 w-36 px-3 inline-flex ml-3" 
                                placeholder="Custom answer">
                        </template>
                    </div>

        
                 
                    <div v-if="(question.answer && question.possible_answers.indexOf(question.answer) !== 0 && question.possible_additional_answers.length > 1 && question.additional_answer == 0) ||
                                (question.answer == 'Nein' && question.additional_answer == 0)" 
                        class="mt-2 text-caption-upper text-5">Es muss mindestens eine Antwort ausgewählt werden
                    </div>
                

                </div>

                <div v-show="!compact" class="py-6 mt-3">
                    <button @click="skipAnamnese()" :disabled="isSkipAnamneseDisabled" :class="{ 'opacity-50': isSkipAnamneseDisabled }" class="button-sm button-warning flex items-center text-1 px-4 py-2 radius-sm leading-4" type="submit">
                        Anamnese überspringen
                        <IconChevronRight class="ml-3 h-2" />
                    </button>
                </div>
                <div v-show="!compact" class="mt-4">
                    <AppWizard step="1" stepRoute="test-sequence-anamnese" :disabled="isWizardDisabled || !answersValid" @switched="handleStepSwitch" />
                </div>

            </div>
        </div>
    </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { ref, toRaw, reactive, computed, onMounted, watch, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import AppTooltip from '@/views/components/common/AppTooltip.vue'
import AppWizard from '@/views/components/common/AppWizard.vue'

import IconCheckbox from '@/views/components/icons/IconCheckbox.vue'
import IconCheckboxChecked from '@/views/components/icons/IconCheckboxChecked.vue'
import IconInfo from '@/views/components/icons/IconInfo.vue'
import IconChevronRight from '@/views/components/icons/IconChevronRight.vue'


const store = useStore()
const router = useRouter()
const route = useRoute()

defineProps({
  compact: {
    type: Boolean,
    required: false,
    default: false
  }
})

const showCustomAdditionalAnswer = ref(false)

const customAdditionalAnswer = ref('')

const patientId = route.params.patientId

const treatmentId = route.params.treatmentId

let answerData = reactive({
    questionId: '',
    answer: '',
    additional_answer: '',
    custom_additional_answer: ''
})

let questions = computed(() => store.getters['anamnesequestions/all'])

let answersValid = ref(false)
let isWizardDisabled = ref(true)

let _isSkipAnamneseDisabled = ref(true)

let isSkipAnamneseDisabled = computed(() => {
    return _isSkipAnamneseDisabled.value || (questionsWithAnswers.value.length == questionsAnswered.value.length)
})

let questionsWithAnswers = ref([])
let questionsAnswered = ref([])

watch(() => toRaw(questions), (i, questions) => {

    //console.log(toRaw(questions.value))

    let questionsArray = toRaw(questions.value)

    questionsWithAnswers.value = []
    questionsAnswered.value = []

    let invalid = false

    let firstQuestion = questionsArray[0]

    let firstQuestionAnswered = false

    if (firstQuestion && firstQuestion.possible_answers && firstQuestion.possible_answers.length && 
        firstQuestion.answer && firstQuestion.possible_answers.indexOf(firstQuestion.answer) > -1) {
        
        firstQuestionAnswered = true
        _isSkipAnamneseDisabled.value = false
    }


    for (const question of questionsArray) {
        if ((question.answers && question.answers.length) ||
            (question.possible_answers && question.possible_answers.length)) {
            
                questionsWithAnswers.value.push(question)
        }
        
        if ((question.answers && question.answers.length && question.answer) ||
            (question.answer && question.possible_answers && question.possible_answers.length && question.additional_answer) ||
            (
                question.answer && question.additional_answer == 0 && question.possible_additional_answers.length && 
                question.possible_additional_answers[question.possible_answers.indexOf(question.answer)] && question.possible_additional_answers[question.possible_answers.indexOf(question.answer)][0] == '' 
            ) ||
            (question.answer && question.possible_additional_answers.length == 0)) {
            
                questionsAnswered.value.push(question)
        }
    }

    for (const question of questionsArray) {
        //console.log(question)
        invalid = (question.answer == 0) ||
                  (question.answer && question.possible_answers.indexOf(question.answer) !== 0 && (question.possible_additional_answers.length != 0 && question.additional_answer == 0)) ||
                  (question.answer == 'Nein' && question.additional_answer == 0)


        if(invalid) {
            answersValid.value = false

            return 
        }

    }

    //removed -  all questions must be answered!
    //isWizardDisabled.value = questionsWithAnswers.value.length != questionsAnswered.value.length
    
    isWizardDisabled.value = !firstQuestionAnswered

    answersValid.value = true

}, { deep: true })


const group = 'anamnese'

onMounted(async () => {
    console.log(treatmentId)
    console.log('anamnese')
    console.log(group)
    await store.dispatch('anamnesequestions/all', { patientId, treatmentId, group })

   console.log(questions.value)
})

let handleStepSwitch = async function({ routeName }) {
    await store.dispatch('anamnesequestions/clearQuestions')

    if(routeName == 'test-sequence-control' && !route.params.test) {
        
        router.push({ 
            name: 'test-sequence-test-order',
            params: {
                ...route.params,
                patientId,
                treatmentId
            }
        })

        return
    }
    

    router.push({ 
        name: routeName,
        params: {
            ...route.params,
            patientId,
            treatmentId
        }
    })
}

let skipAnamnese = async function() {

    await store.dispatch('anamnesequestions/skipQuestions', {
        patientId,
        treatmentId,
        group
    })

    router.push({ 
        name: 'test-sequence-strabismus',
        params: {
            ...route.params,
            patientId,
            treatmentId
        }
    })
}

let handleQuestionAnswer = async function(questionId, answer) {
    console.log('question ' + questionId + '   - ' + answer)

    let answerResp = await store.dispatch('anamnesequestions/updateQuestion', {
        patientId,
        treatmentId,
        questionId,
        data: {
            answer,
            additional_answer: 0
        }
    })

    console.log(answerResp)
    
}

let handleQuestionAdditionalAnswer = async function(questionId, answer, additionalAnswerIndex, showAdditionalCustomAnswer) {
    console.log('question ' + questionId + '   - ' + additionalAnswerIndex)

    if(showAdditionalCustomAnswer) {
        showCustomAdditionalAnswer.value = !showCustomAdditionalAnswer.value
    }  else {
        showCustomAdditionalAnswer.value = false
    }

    let answerResp = await store.dispatch('anamnesequestions/updateQuestion',{
        patientId,
        treatmentId,
        questionId,
        data: {
            answer,
            additional_answer: additionalAnswerIndex
        }
    })

    console.log(answerResp)
    
}

let handleQuestionCustomAdditionalAnswerEdit = function(questionId, answer, additionalAnswerIndex) {

    console.log(questionId)
    console.log(additionalAnswerIndex)


    answerData.questionId = questionId
    answerData.answer = answer
    answerData.additional_answer = additionalAnswerIndex
}

let handleQuestionCustomAdditionalAnswerBlur = async function(event) {
    console.log("blur")
    answerData.custom_additional_answer = event.target.value

    let answerResp = await store.dispatch('anamnesequestions/updateQuestion', {
        patientId,
        treatmentId,
        questionId: answerData.questionId,
        data: {
            answer: answerData.answer,
            additional_answer: answerData.additional_answer,
            custom_additional_answer: answerData.custom_additional_answer
        }
    })

    console.log(answerResp) 
}

let handleQuestionCustomAdditionalAnswer = async function(event) {
    console.log(event)
    if(event.key == 'Enter') {

        answerData.custom_additional_answer = event.target.value

        let answerResp = await store.dispatch('anamnesequestions/updateQuestion', {
            patientId,
            treatmentId,
            questionId: answerData.questionId,
            data: {
                answer: answerData.answer,
                additional_answer: answerData.additional_answer,
                custom_additional_answer: answerData.custom_additional_answer
            }
        })

        console.log(answerResp)   
    } else if(event.key == 'Escape') {

    } else {
        answerData.custom_additional_answer = event.target.value

    }

}

</script>
<style scoped>

.answers > label.button-primary {
    display: inline-flex;
    line-height: 17px !important;
    margin-top: 15px;
}

.additional-answers > label, .additional-answers > input {
    display: inline-flex;
    height: auto;
    margin-top: 15px;
    line-height: 15px !important;
}

/*
button.button-primary {
    margin-bottom: 1.5rem;
}*/
</style>