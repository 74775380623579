<template>
    <div class="login-panel surface-brand-2 w-full grid place-items-center">

        <div :class="{'animate__animated animate__headShake': unsuccessful}" class="w-1/4 mx-auto surface-2 shadow-1 rounded px-8 pt-6 pb-8 mb-4" style="min-width: 416px;">

            <div class="flex items-center flex-col">
                <!-- <img alt="BTSO logo" class="logo heading-4-medium-s" src="@/assets/logo-main.svg" />-->
                <BtsoLogo class="w-32" />
            </div>
            <p class="heading-5-medium text-center my-6">{{ !show2FA ? 'Login' : 'Aus Sicherheitsgrunden benötigen wir zusätzliche Informationen, um Ihr Konto zu verifizieren' }}</p>
            <form v-if="show2FA" v-on:submit.prevent="login2FA">
                <div :class="{ 'has-error': errors2FA.code[0] }" class="mb-7">
                    <label class="input-label-lg text-1 mb-2 uppercase" for="code">
                    Passcode
                    </label>
                    <input v-model="form2FA.code" @focus="resetLogin2FA('code')" class="input border rounded w-full" id="code" type="text" placeholder="Example 00000">
                    <span class="feedback" v-if="errors2FA.code[0]">{{ errors2FA.code[0] }}</span>
                </div>

                <div class="flex items-center justify-between">
                    <button class="button-primary w-full flex items-center justify-center" type="submit">
                        <template v-if="isLoading">
                            <div class="loading-spinner mr-3"></div> Checking...
                        </template>
                        <template v-else>
                            WEITER
                        </template>
                    </button>
                </div>


            </form>
            <form v-else class="pt-5" v-on:submit.prevent="login">
                <div :class="{ 'has-error': errors.email[0] }" class="mb-7">
                    <label class="input-label-lg text-1 mb-2 uppercase" for="email">
                    E-mail-adresse
                    </label>
                    <input v-model="form.email" @focus="resetLogin('email')" class="input border rounded w-full" id="email" type="text" placeholder="Bitte geben Sie hier Ihre E-Mail-Adresse an">
                    <span class="feedback" v-if="errors.email[0]">{{ errors.email[0] }}</span>
                </div>
                <div :class="{ 'has-error': errors.password[0] }" class="mb-2">
                    <label class="input-label-lg text-1 mb-2 uppercase" for="password">
                        Passwort
                    </label>
                    <input v-model="form.password" @focus="resetLogin('password')" class="input border rounded w-full" id="password" type="password" placeholder="Bitte geben Sie hier Ihr Passwort an">
                    <span class="feedback" v-if="errors.password[0]">{{ errors.password[0] }}</span>
                </div>
                <div>
                    <button type="button" @click="showPasswordResetModal()">Passwort vergessen?</button>
                </div>

                <div v-if="unsuccessful" class="h-6 error-login text-red-500 text-center">Login unsuccessful!</div>
                <div v-else class="h-6 error-login text-red-500 text-center">&nbsp;</div>

                <div class="flex items-center justify-between">
                    <button class="button-primary w-full flex items-center justify-center" type="submit">
                        <template v-if="isLoading">
                            <div class="loading-spinner mr-3"></div> Logging in...
                        </template>
                        <template v-else>
                            Anmelden
                        </template>
                    </button>
                </div>


                <RouterLink class="block text-center w-full align-baseline py-2 text-caption-upper text-2 underline" to="/register">Sind Sie noch kein Mitglied?</RouterLink>
                <RouterLink class="block text-center w-full align-baseline py-2 text-caption-upper text-2 underline" to="/test-login">Test Login kostenlos für 4 Wochen</RouterLink>

            </form>
        </div>
    </div>

    <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="rightsChangeConfirmationModal">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">
            <div class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10">ROLLE ÄNDERN</h1>
                <p class="w-96 mx-auto mt-7 leading-4">
                    Sind Sie sicher, dass Sie die Rolle dieses Benutzers ändern wollen?
                </p>
                <div class="flex items-center justify-between mt-7">
                    <button @click="changeUserRights()" class="button-primary w-96 mx-auto">
                        Ja
                    </button>
                </div>
                <div class="flex items-center justify-between mt-7">
                    <button @click="closeChangeUserRightsConfirmation()" class="button-primary-outlined w-96 mx-auto">
                        Abbrechen
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>

    <AppConfirmModal class="modal-dimmer xl:w-1/4" ref="passwordResetModal">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">
            <div class="flex flex-col text-center h-96 w-4/5 mx-auto">
                <h1 class="heading-1-bold-s pt-10">Passwort vergessen?</h1>
                <p class=" mx-auto mt-7 mb-7 leading-4 w-96">
                    Bitte tragen Sie Ihre E-Mail-Adresse ein,<br> damit wir Ihnen einen Link zum Zurücksetzen Ihres Passworts zusenden können.
                </p>
                <form action="#" @submit.prevent="requestPasswordReset()">
                    <div class="mb-7" :class="{ 'has-error': errorsPasswordResetForm.email[0] }" >
                        <label class="input-label-lg text-1 mb-2 uppercase" for="email">
                        E-mail-adresse
                        </label>
                        <input @focus="resetPasswordResetForm('email')" v-model="passwordResetForm.email" class="input border rounded w-full mx-auto"
                            id="password-reset-email" type="email"
                            placeholder="Bitte geben Sie hier Ihre E-Mail-Adresse an">
                        <span class="feedback" v-if="errorsPasswordResetForm.email[0]">{{ errorsPasswordResetForm.email[0] }}</span>
                    </div>
                    <div class="flex items-center justify-between mt-7">
                        <button type="submit" class="button-primary w-96 mx-auto flex items-center justify-center">
                            <template v-if="isLoading">
                                <div class="loading-spinner"></div><div class="ml-2"></div>Senden...
                            </template>
                            <template v-else>
                                Senden
                            </template>
                        </button>
                    </div>
                </form>
                <!-- <div class="flex items-center justify-between mt-7">
                    <button @click="closePasswordResetModal()" class="button-primary-outlined w-96 mx-auto">
                        Abbrechen
                    </button>
                </div>-->
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>

</template>
<script setup>
import { useStore } from 'vuex'
import { ref, toRaw, reactive, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import AppConfirmModal from '@/views/components/common/AppConfirmModal.vue'

import BtsoLogo from '@/views/components/icons/BtsoLogo.vue'

const store = useStore()
const router = useRouter()
const toast = useToast()

const passwordResetModal = ref()

const form2FA = reactive({
    code: '',
    email: '',
    password: '',
})

let errors2FA = reactive({
    code: [],
})

const form = reactive({
    email: '',
    password: '',
})

let errors = reactive({
    email: [],
    password: [],
})

const passwordResetForm = reactive({
    email: '',
})

let errorsPasswordResetForm = reactive({
    email: [],
})

let unsuccessful = ref(false)
let unsuccessful2FA = ref(false)

let show2FA = ref(false)

const isLoading = computed(() => store.getters['auth/isLoading'])

const resetLogin = (field) => {
    console.log(field)
    errors[field] = []
    unsuccessful.value = false
}

const resetLogin2FA = (field) => {
    console.log(field)
    errors2FA[field] = []
    unsuccessful2FA.value = false
}

const resetPasswordResetForm  = (field) => {
    console.log(field)
    errorsPasswordResetForm[field] = []
}


const login = async() => {
    unsuccessful.value = false
    unsuccessful2FA.value = false

    let credentials = toRaw(form)

    let resp = await store.dispatch('auth/login', credentials).catch((err) => {
        Object.assign(errors, err.errors)
    })

    if (resp) {

        if (resp.two_auth_required && resp.two_auth_method === 'email') {
            form2FA.email = credentials.email
            form2FA.password = credentials.password

            show2FA.value = true

        } else {

            form.email = ''
            form.password = ''

            if (sessionStorage && sessionStorage.getItem('intended')) {
                let to = JSON.parse(sessionStorage.getItem('intended'))
                return router.push({ name: to.name, params: to.params, query: to.query })
            }

            router.push({ name: 'patients' })
        }



    } else {
        if (!(errors.email.length && errors.password.length)) {
            unsuccessful.value = true
        }
    }
}

const login2FA = async() => {
    let credentials = toRaw(form2FA)

    let resp = await store.dispatch('auth/login2FA', credentials).catch((err) => {
        Object.assign(errors2FA, err.errors)
    })

    if (resp) {
        form.email = ''
        form.password = ''

        form2FA.email = ''
        form2FA.password = ''
        form2FA.code = ''

        if (sessionStorage && sessionStorage.getItem('intended')) {
            let to = JSON.parse(sessionStorage.getItem('intended'))
            return router.push({ name: to.name, params: to.params, query: to.query })
        }

        router.push({ name: 'patients' })
    } else {
        if (!(errors2FA.code.length)) {
            unsuccessful2FA.value = true
        }
    }
}

let requestPasswordReset = async function() {

    let payload = toRaw(passwordResetForm)

    console.log(payload)

    let resp = await store.dispatch('auth/requestPasswordReset', payload).catch((err) => {
        Object.assign(errorsPasswordResetForm, err.errors)
    })

    if(resp) {
        passwordResetForm.email = ''
        errorsPasswordResetForm.email = []

        if(resp.message) {
            closePasswordResetModal()

            toast.default(resp.message, { position: 'top-right' })
        }
    }

}

let showPasswordResetModal = async function() {
    await passwordResetModal.value.show().catch((err) => {
        //console.log(err)
    })
}

let closePasswordResetModal = function() {
    passwordResetModal.value.close()
}

onMounted(() => {
    store.dispatch('app/showMenu', false)
})

</script>

<style scoped>

</style>