import { createRouter, createWebHistory } from 'vue-router'
import before from './before'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/pages/LoginView.vue'
import RegistrationView from '../views/pages/RegistrationView.vue'
import UsersView from '../views/pages/UsersView.vue'
import PatientsView from '../views/pages/PatientsView.vue'
import PasswordResetView from '../views/pages/PasswordResetView.vue'
import AnamneseView from '../views/pages/AnamneseView.vue'
import StrabismusView from '../views/pages/StrabismusView.vue'
import TestOrderView from '../views/pages/TestOrderView.vue'
import TestControlView from '../views/pages/TestControlView.vue'
import FastTestSquenceView from '../views/pages/FastTestSquenceView.vue'
import TestEvaluationView from '../views/pages/TestEvaluationView.vue'
import DocumentsView from '../views/pages/DocumentsView.vue'
import SettingsView from '../views/pages/SettingsView.vue'


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: { name: 'patients' },
      //component: HomeView
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: {
        guest: true
      }
    },
    {
      path: '/register',
      name: 'register',
      component: RegistrationView,
      meta: {
        guest: true
      }
    },
    {
      path: '/test-login',
      name: 'testlogin',
      component: RegistrationView,
      meta: {
        testLogin: true,
        guest: true
      }
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: PasswordResetView,
      meta: {
        guest: true
      }
    },
    {
      path: '/users',
      name: 'users',
      component: UsersView,
      meta: {
        auth: true
      }
    },
    {
      path: '/patients',
      name: 'patients',
      component: PatientsView,
      meta: {
        auth: true
      }
    },
    {
      path: '/test-sequence/patients/:patientId/treatments/:treatmentId/anamnese',
      name: 'test-sequence-anamnese',
      component: AnamneseView,
      meta: {
        auth: true
      }
    },
    {
      path: '/test-sequence/patients/:patientId/treatments/:treatmentId/strabismus',
      name: 'test-sequence-strabismus',
      component: StrabismusView,
      meta: {
        auth: true
      }
    },
    {
      path: '/test-sequence/patients/:patientId/treatments/:treatmentId/test-order',
      name: 'test-sequence-test-order',
      component: TestOrderView,
      meta: {
        auth: true
      }
    },
    {
      path: '/test-sequence/patients/:patientId/treatments/:treatmentId/control/:test/:subview?',
      name: 'test-sequence-control',
      component: TestControlView,
      meta: {
        auth: true
      }
    },
    {
      path: '/test-sequence-fast/patients/:patientId/treatments/:treatmentId',
      name: 'test-sequence-fast',
      component: FastTestSquenceView,
      meta: {
        auth: true
      }
    },
    {
      path: '/test-evaluation/patients/:patientId/treatments/:treatmentId',
      name: 'test-evaluation',
      component: TestEvaluationView,
      meta: {
        auth: true
      }
    },
    {
      path: '/documents',
      name: 'documents',
      component: DocumentsView,
      meta: {
        auth: true
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: SettingsView,
      meta: {
        auth: true
      }
    },
  ]
})

router.beforeResolve(before)

export default router
