import axios from 'axios'

export default function use() {

    const index = async(patientId, treatmentId, group) => {

        let response = await axios.get(`/api/patients/${patientId}/treatments/${treatmentId}/questions?group=${group}`).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let questions = response.data

        console.log(questions)

        return Promise.resolve(questions)
    }

  
    const update = async(patientId, treatmentId, questionId, payload) => {

        let response = await axios.put(`/api/patients/${patientId}/treatments/${treatmentId}/questions/${questionId}`, payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let question = response.data.data

        return Promise.resolve(question)
    }

    const skip = async(patientId, treatmentId, group) => {

        let response = await axios.post(`/api/patients/${patientId}/treatments/${treatmentId}/skip-questions-group`, {
            group: group
        }).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let data = response.data.data

        return Promise.resolve(data)
    }


   
    return {
        index,
        update,
        skip
    }

}