<template>
  <AppModal class="modal-dimmer w-1/3 px-6 py-2" v-show="true" @close="closeModalAfterTest()" >
    <template v-slot:header>
      <template></template>
    </template>
    <template v-slot:body scope="props">
      <div class="flex flex-col text-center">
        <h1 class="text-xl font-bold pt-2">Vielen Dank, dass Sie die BTSO Lern-App getestet haben.</h1>
        <h2 class="text-xl font-bold w-5/6 mx-auto">Ihr kostenloses Testkonto für die BTSO Lern-App läuft jetzt ab.</h2>
        <p class="text-md w-2/3 mx-auto mt-3 leading-4">
          Wenn Sie von der Nutzung der App überzeugt sind, laden wie Sie ein, die Lern-App auch mit Speicherung aller Daten über die Seite <a href="http://www.btso.ch" target="_blank" class="border-b border-blue-700 text-blue-700">www.btso.ch</a> zu bestellen.
        </p>
        <p class="text-md w-5/6 mx-auto mt-3 leading-4">
          Werden Sie mit der Nutzung der BTSO Lern-App ein Teil der neuen Community, die sich um Menschen mit binokularen und akkommodativen Problemen kümmert. Diese Kundinnen und Kunden haben bisher zu wenig Unterstützung finden können. Nutzen Sie auch den Support und den regelmässigen, fachlichen Austausch. Wir freuen uns auf Sie.
        </p>
      </div>
    </template>
    <template v-slot:footer>
      <template></template>
    </template>
  </AppModal>
</template>

<script setup>
import AppModal from '@/views/components/common/AppModal.vue'

const closeModalAfterTest = async() => {
  const modalData = document.querySelector('.modal-after-test-wrapper')
  if(modalData) {
    modalData.classList.add('hidden');
  }
};
</script>
