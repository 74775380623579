<template>
    <div class="flex flex-1 surface-4 p-3">
        <div class="flex-1 p-3 surface-2 rounded'">
            <h1 class="heading-3-medium text-1">Auswertung der Daten anhand der Profillinie</h1>
            <!--<button @click.prevent="exportGraphImg()">export img</button>-->

            <div class="h-1 hr-divider mb-4"></div>
            <div class="bg-white rounded px-3 pt-10 pb-5">

                <div class="bg-white flex items-center justify-center text-1 text-p-3" :class="{ 'hidden': !isLoading }" :style="{ height: calculateInnerHeight() }"><div class="loading-spinner mr-3"></div> Loading...</div>

                <div class="outter rounded-lg px-5 py-3">
                    <div class="inner" ref="toimg" :class="{ 'bg-white mx-auto converting': isConverting }">
                        <div class="anamnese-panel-outter xl:w-3/4">
                            <div v-if="anamnese" class="panel anamnese-panel p-6 bg-white rounded w-full xl:w-9/12 mx-auto" :class="{ 'shadow-md': !isConverting }">

                                <div :class="{ 'opacity-30': anamnese.status == 'skipped' }"  class="flex items-center pr-10">
                                    <div class="w-4/12 text-p-3-bold text-1 text-right px-3">Anamnese</div>

                                    <div class="distance-graph-panel flex items-center w-full heading-6-medium text-1 surface-brand-3 rounded">
                                        <div v-if="anamnese.status == 'suspicious'" class="w-full h-7 rounded flex items-center justify-center bg-red"><span :class="{ '-top-3': isConverting }">Auffällig</span></div>
                                        <div v-if="anamnese.status == 'ok'" class="w-full h-7 rounded flex items-center justify-center bg-green"><span :class="{ '-top-3': isConverting }">OK</span></div>
                                        <div v-if="anamnese.status == 'skipped'" class="w-full h-7 rounded flex items-center justify-center bg-neutral"><span :class="{ '-top-3': isConverting }">Übersprungen</span></div>

                                    </div>
                                </div>

                                <div :class="{ 'opacity-30': anamnese.status == 'skipped' }" class="flex items-center mt-10 pr-10">
                                    <div class="w-4/12 text-p-3-bold text-1 text-right px-3">Sehbdingte Kopfschmerzen in den Distanzen</div>

                                    <div class="distance-graph-panel flex items-center w-full heading-6-medium text-1 surface-brand-3 rounded">
                                        <div class="w-1/3 h-7 rounded flex items-center justify-center" :class="{ 'bg-green': !anamnese.visual_headaches.near, 'bg-red': anamnese.visual_headaches.near, 'bg-neutral': anamnese.status == 'skipped' }"><span :class="{ '-top-3': isConverting }">Nähe</span></div>
                                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center" :class="{ 'bg-green': !anamnese.visual_headaches.pc, 'bg-red': anamnese.visual_headaches.pc, 'bg-neutral': anamnese.status == 'skipped'  }"><span :class="{ '-top-3': isConverting }">PC</span></div>
                                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center" :class="{ 'bg-green': !anamnese.visual_headaches.far, 'bg-red': anamnese.visual_headaches.far, 'bg-neutral': anamnese.status == 'skipped'  }"><span :class="{ '-top-3': isConverting }">Ferne</span></div>
                                    </div>
                                </div>

                                <div :class="{ 'opacity-30': anamnese.status == 'skipped' }" class="flex items-center mt-10 pr-10">
                                    <div class="w-4/12 text-p-3-bold text-1 text-right px-3">Doppeltsehen in folgendedn Distanzen</div>

                                    <div class="distance-graph-panel flex items-center w-full heading-6-medium text-1 surface-brand-3 rounded">
                                        <div class="w-1/3 h-7 rounded flex items-center justify-center" :class="{ 'bg-green': !anamnese.double_vision.near, 'bg-red': anamnese.double_vision.near, 'bg-neutral': anamnese.status == 'skipped' }"><span :class="{ '-top-3': isConverting }">Nähe</span></div>
                                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center" :class="{ 'bg-green': !anamnese.double_vision.pc, 'bg-red': anamnese.double_vision.pc, 'bg-neutral': anamnese.status == 'skipped' }"><span :class="{ '-top-3': isConverting }">PC</span></div>
                                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center" :class="{ 'bg-green': !anamnese.double_vision.far, 'bg-red': anamnese.double_vision.far, 'bg-neutral': anamnese.status == 'skipped' }"><span :class="{ '-top-3': isConverting }">Ferne</span></div>
                                    </div>
                                </div>

                                <div :class="{ 'opacity-30': anamnese.status == 'skipped' }" class="flex items-center mt-10 pr-10">
                                    <div class="w-4/12 text-p-3-bold text-1 text-right px-3">Sehbedingte Probleme in den Distanzen</div>

                                    <div class="distance-graph-panel flex items-center w-full heading-6-medium text-1 surface-brand-3 rounded">
                                        <div class="w-1/3 h-7 rounded flex items-center justify-center" :class="{ 'bg-green': !anamnese.visual_problems.near, 'bg-red': anamnese.visual_problems.near, 'bg-neutral': anamnese.status == 'skipped' }"><span :class="{ '-top-3': isConverting }">Nähe</span></div>
                                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center" :class="{ 'bg-green': !anamnese.visual_problems.pc, 'bg-red': anamnese.visual_problems.pc, 'bg-neutral': anamnese.status == 'skipped' }"><span :class="{ '-top-3': isConverting }">PC</span></div>
                                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center" :class="{ 'bg-green': !anamnese.visual_problems.far, 'bg-red': anamnese.visual_problems.far, 'bg-neutral': anamnese.status == 'skipped' }"><span :class="{ '-top-3': isConverting }">Ferne</span></div>
                                    </div>
                                </div>
    <!--
                                <div class="flex items-center mt-10 pr-10">

                                        <div class="w-4/12 text-p-3-bold text-1 text-right px-3" :class="{ '-top-3': isConverting }"><span class="points inline-flex items-center h-8 rounded px-8 mr-3"><span :class="{ '-top-3': isConverting }">19</span></span><span class="uppercase font-bold tracking-wide mr-2">Punkte</span> Anamnese-Score</div>

                                    <div class="distance-graph-panel flex items-center w-full heading-6-medium text-1 surface-brand-3 rounded">
                                        <div class="w-1/6 h-7 rounded flex items-center justify-center bg-green-lighter"></div>
                                        <div class="w-1/6 h-7 ml-0.5 rounded flex items-center justify-center bg-green-light"><span class="indicator rounded w-4 h-5"></span></div>
                                        <div class="w-1/3 h-7 ml-0.5 rounded flex items-center justify-center bg-green"></div>
                                        <div class="w-1/6 h-7 ml-0.5 rounded flex items-center justify-center bg-green-light"></div>
                                        <div class="w-1/6 h-7 ml-0.5 rounded flex items-center justify-center bg-green-lighter"></div>

                                    </div>
                                </div>
      -->
                            </div>
                        </div>


                        <div class="panel graph-panel mt-6 p-6 bg-white rounded xl:w-3/4" :class="{ 'shadow-md': !isConverting }">
                            <div>
                                <canvas id="chart" class="w-full"></canvas>
                            </div>

                            <canvas id="img1" style="display:none;" width="40" height="26"></canvas>
                            <canvas id="img2" style="display:none;" width="40" height="26"></canvas>
                            <canvas id="img3" style="display:none;" width="40" height="26"></canvas>
                            <canvas id="img4" style="display:none;" width="40" height="26"></canvas>
                            <canvas id="img5" style="display:none;" width="40" height="26"></canvas>
                            <canvas id="img6" style="display:none;" width="40" height="26"></canvas>
                            <canvas id="img7" style="display:none;" width="40" height="26"></canvas>
                            <canvas id="img8" style="display:none;" width="40" height="26"></canvas>

                        </div>
                    </div>

                    <div class="notes mt-6 xl:w-3/6">
                        <p class="subheading-bold mb-3">Übersicht aller Klassen</p>
                        <div v-if="treatment && treatment.system_notes">{{ treatment.system_notes }}</div>
                    </div>

                    <div v-if="documents.length" class="documentation-info-panel xl:w-3/4 mt-4 px-3 py-10 --text-white">
                        <div class="documentation-inner max-h-96 overflow-y-auto px-3">
                            <ul class="--text-white max-h-96 overflow-y-auto pr-1">
                                <li v-for="document in documents" class="flex items-center flex-col pb-6 w-full border-b border-gray-200 mb-6">
                                    <h1 class="text-xl --text-white px-2">{{ document.title }}</h1>
                                    <p class="mt-10" v-html="document.content"></p>
                                    <a :href="document.url" target="_blank" class="doc-link-btn mt-6 inlie-block bg-white rounded">Herunterladen</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="notes mt-6 xl:w-3/6">
                        <p class="subheading-bold mb-3">Eingabewerte</p>
                    </div>

                    <div>
                        <table class="tests-table" width="100%" cellpadding="6" cellspacing="0">
                            <thead>
                                <tr>
                                    <td style="text-align: left;" scope="col"><b>Sequenz</b></td>
                                    <td style="text-align: left;" scope="col"><b>Konkretisierung</b></td>
                                    <td style="text-align: right;" scope="col"><b>Werte</b></td>
                                    <td style="text-align: right;" scope="col"><b>Einheit</b></td>
                                    <td style="text-align: center;" scope="col"><b>Weiteres</b></td>
                                </tr>
                            </thead>
                            <tbody>

                                <template v-for="test in testData">
                                    <tr v-for="property in test.properties">
                                        <td>
                                            <b>{{ test.test }}</b>
                                            <template v-if="property.name != 'Default'">
                                                <br>{{ property.name }}
                                            </template>
                                        </td>
                                        <td style="padding: 6px;">{{ test.method }}</td>
                                        <td style="width: 100px; text-align: right;">{{ property.value }}</td>
                                        <td style="width: 100px; text-align: right;">{{ property.unit }}</td>
                                        <td></td>
                                    </tr>
                                </template>

                            </tbody>
                        </table>
                    </div>

                    <div class="notes mt-6 xl:w-3/6">
                        <textarea v-model="notes" class="bg-transparent w-full h-32" placeholder="Anmerkungen bitte hier eintragen..."></textarea>

                        <div :class="{ 'animate__animated animate__headShake': unsuccessful }">
                            <label class="cursor-pointer"><input v-model="acceptTerms" type="checkbox" class="cursor-pointer"><span class="ml-2">Ich akzeptiere die AGBs</span></label>
                        </div>
                    </div>

                </div>

                <button @click="finalizeTreatment()" class="notes-save-btn button-primary w-full mt-4 px-5 py-2 radius-sm uppercase">SEQUENZ ABSCHLIESSEN</button>

            </div>
        </div>
    </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { ref, toRaw, reactive, computed, onMounted, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import options from '@/options.js'

import IconPdfBig from '@/views/components/icons/IconPdfBig.vue'

import axios from 'axios'
import { jsPDF } from "jspdf"
import html2canvas from 'html2canvas'

//import { Chart } from 'chart.js'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.defaults.font.family = "Overpass"
Chart.defaults.font.weight = "600"
Chart.defaults.font.size = "14px"

const store = useStore()
const router = useRouter()
const route = useRoute()

const patientId = route.params.patientId

const treatmentId = route.params.treatmentId

let treatment = computed(() => store.getters['treatments/treatment'].data)
let graph = computed(() => store.getters['tests/graph'])
let anamnese = computed(() => store.getters['tests/anamnese'])
let documents = computed(() => store.getters['documents/all'])
let testData = computed(() => store.getters['tests/testData'])

let toimg = ref()

let isLoading= ref(true)
let isConverting = ref(false)

let notes = ref('')
let acceptTerms = ref(false)

let unsuccessful = ref(false)

const points = []

const plugin = {
    id: 'custom_canvas_background',
    beforeDraw: (chart) => {

        let ctx = chart.canvas.getContext('2d')
        ctx.save()
        ctx.globalCompositeOperation = 'destination-over'

        const chartArea = chart.chartArea
        let offset = chartArea.left

        //area 1
        let aWidth = Math.ceil(35.46 * chartArea.width / 100) + 1
        let gradientBack = chart.canvas.getContext("2d").createLinearGradient(offset, 0, offset + aWidth, 0)

        /*
        //version 1
        let outerColor1 = '#ffe4d4'
        let outerColor2 = '#e6a8a8'
        let innerColor1 = '#e8f4dc'
        let innerColor2 = '#c8e4b4'
        */
        /*
        //version 2
        let outerColor1 = '#f5594e'
        let outerColor2 = '#e8827b'
        let innerColor1 = '#c4d4b4'
        let innerColor2 = '#8ca374'
        */

        //version 3
        let outerColor1 = '#dba8a4'
        let outerColor2 = '#ffe4d4'
        let innerColor1 = '#e8f4dc'
        let innerColor2 = '#c8e4b4'


        //version 4
        /*
        let outerColor1 = '#872222'
        let outerColor2 = '#e6a8a8'
        let innerColor1 = '#18db8d'
        let innerColor2 = '#0b8756'
        */

        gradientBack.addColorStop(0, outerColor1)
        gradientBack.addColorStop(1, outerColor2)

        ctx.fillStyle = gradientBack
        ctx.fillRect(offset, chartArea.top, aWidth, chartArea.height)
        offset += aWidth

        //area 2
        aWidth = Math.ceil(14.24 * chartArea.width / 100)
        gradientBack = chart.canvas.getContext("2d").createLinearGradient(offset, 0, offset + aWidth, 0)

        gradientBack.addColorStop(0, innerColor1)
        gradientBack.addColorStop(0.5, innerColor2)

        ctx.fillStyle = gradientBack
        ctx.fillRect(offset, chartArea.top, aWidth, chartArea.height)
        offset += aWidth

        //area 3
        aWidth = Math.ceil(14.24 * chartArea.width / 100)
        gradientBack = chart.canvas.getContext("2d").createLinearGradient(offset, 0, offset + aWidth, 0)

        gradientBack.addColorStop(0.5, innerColor2)
        gradientBack.addColorStop(1, innerColor1)

        ctx.fillStyle = gradientBack
        ctx.fillRect(offset, chartArea.top, aWidth, chartArea.height)
        offset += aWidth

        //area 4
        aWidth = Math.ceil(35.46 * chartArea.width / 100)
        gradientBack = chart.canvas.getContext("2d").createLinearGradient(offset, 0, offset + aWidth, 0)

        gradientBack.addColorStop(0, outerColor2)
        gradientBack.addColorStop(1, outerColor1)

        ctx.fillStyle = gradientBack
        ctx.fillRect(offset, chartArea.top, aWidth, chartArea.height)
        offset += aWidth

        ctx.restore()
    }

}

let finalizeTreatment = async function() {

    if(acceptTerms.value) {
        let treatment = await store.dispatch(`treatments/finalizeTreatment`, {
            patientId,
            treatmentId,
            data: {
                finished: 1,
                notes: notes.value,
                accept_terms: acceptTerms.value
            }
        }).catch((error) => {})

        window.open(getEvaluationDownloadLink(treatmentId), '_blank')

        if(treatment) {
            router.push({
                name: 'patients',
            })
        }

    } else {

        unsuccessful.value = true

        setTimeout(() => {
            unsuccessful.value = false
        }, 1200, true)
    }
}
let getEvaluationDownloadLink = function(treatmentId) {
        return `${options.baseUrl}/patients/${patientId}/treatments/${treatmentId}/evaluation-download`
}

let calculateInnerHeight = function() {
    return window.innerHeight + 'px'
}

let exportGraphImg = function() {
    isConverting.value = true
    //var doc = new jsPDF('p', 'pt', 'a4');

    /*const canvas = document.getElementById('myChart')
    const ctx =  canvas.getContext("2d");

    var imgData = canvas.toDataURL('image/png');
    var pdf = new jsPDF('landscape');
    pdf.addImage(imgData, 'PNG', 0, 0, 1350, 750);*/



    // doc.html(toimg.value.innerHTML, {
    //     'x': 15,
    //     'y': 15,
    //     'width': 800,
    // })

    //doc.html(toimg.value.innerHTML);

    //doc.save("download.pdf");

    //var doc = new jsPDF()


    // doc.canvas.width  = 400;
    // doc.canvas.height = 300;
    // doc.canvas.style.width  = '400px';

    setTimeout(function() {
        html2canvas(toimg.value).then((canvas) => {
            canvas.toBlob(function(blob) {

                console.log(blob.text())

                let fd = new FormData()

                fd.append('fname', 'graph.png')
                fd.append('evaluation_image', blob)

                console.log(fd)

                axios({
                    method: "post",
                    url: `api/patients/${patientId}/treatments/${treatmentId}/upload-evaluation-image`,
                    data: fd,
                    headers: {
                        'Content-Type': `multipart/form-data;`,
                    }
                })
                .then(function (response) {
                    //handle success
                    console.log(response)
                })
                .catch(function (response) {
                    //handle error
                    console.log(response)
                })

                //window.saveAs(blob, 'my_image.jpg')
                //var link = window.URL.createObjectURL(blob)
                //window.location = link
                //window.open(link, '_blank')

                isConverting.value = false
                isLoading.value = false
            })
        })

    }, 1000)




}

onMounted(async () => {

    window.scrollTo({ top: 0 })

    console.log(toimg.value.innerHTML)
    await store.dispatch('tests/graph', { patientId, treatmentId })

    await store.dispatch('treatments/treatment', { patientId, treatmentId })

    await store.dispatch('documents/all', treatmentId)


    notes.value = treatment.value.notes

    const ctx = document.getElementById('chart')

/*
    graph.value.data.forEach((element, index) => {
        const imgC = document.getElementById("img" + (index + 1))
        if (imgC instanceof HTMLCanvasElement) {
            const ctxC = imgC.getContext('2d')

            ctxC.fillStyle = '#003A56'
            ctxC.fillRect(0, 0, 40, 26)

            ctxC.fillStyle = '#ffffff'
            ctxC.font = '14px sans-serif'
            ctxC.fillText(graph.value.measured[index], 4, 18)

            points[points.length] = imgC
        }
    })*/

/*
    const labels = graph.value.labels;
    labels.forEach((element, index) => {
        labels[index] = element.padEnd(40, ' ').padStart(40, ' ');
    })*/

    const chart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: graph.value.labels,
            datasets: [{
                axis: 'y',
                label: 'Behandlugsverlauf',
                data: graph.value.data,
                fill: false,
                borderColor: '#003A56',
                borderWidth: 1,
                //pointStyle: [img, img2, img3, img, img, img, img, img],
                //pointStyle: [imgC, imgC, imgC, imgC, imgC, imgC, imgC, imgC],
                /*pointStyle: points,*/
                pointRadius: 0,
            }]
        },
        options: {
            responsive: true,
            indexAxis: 'y',
            scales: {
                x: {
                    beginAtZero: true,
                    display: true,
                    offset: true,
                    min: -3,
                    max: 3,
                    position: 'top',
                    ticks: {
						font: {
							family: 'Inter',
						}
					}
                },
                y: {
                    offset: true,
                    afterFit: function(scaleInstance) {
                        scaleInstance.width = 260; // sets the left labels width to 260px
                    },
                    ticks: {
						font: {
							family: 'Inter',
						}
					}
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                /*
                tooltip: {
                    callbacks: {
                        labelColor: function (context) {
                            return {
                                borderColor: '#003A56',
                                backgroundColor: '#003A56',
                                borderWidth: 1,
                                borderDash: [0, 0],
                                borderRadius: 0,
                                fontStyle: "bold"
                            };
                        },
                        label: function (context) {
                            return graph.value.measured[context.dataIndex];
                        },
                    },
                },
                */
                datalabels: {
                    backgroundColor: function(context) {
                        return graph.value.bgcolors[context.dataIndex]
                    },
                    borderRadius: 4,
                    color: 'white',
                    font: {
                        weight: 'bold',
                        family: 'Inter',
                    },
                    padding: 6,
                    formatter: function(value, context) {
                        return graph.value.measured[context.dataIndex];
                    }
                },
                title: {
                    display: true,
                    text: 'Standardabweichungen',
                    fullSize: false,
                    font: {
                        weight: 'normal',
                        family: 'Inter',
                    }
                },
            },
        },
        plugins: [plugin, ChartDataLabels],
    })


    exportGraphImg()

})

</script>
<style scoped>
    canvas {
        max-width: 100% !important;
    }
    .outter {
        /*background: #f0faff;*/
        background: var(--color-shade-3);
    }

    .bg-green {
        background: var(--color-success-400);
    }

    .bg-green-light {
        background: var(--color-success-300);
    }

    .bg-green-lighter {
        background: var(--color-success-100);
    }

    .bg-red {
        background: var(--color-error-300);
    }

    .bg-red-light {
        background: var(--color-error-200);
    }

    .bg-neutral {
        background: var(--color-neutral-100);
    }

    .distance-graph-panel {
        border: 2px solid var(--color-shade-1);
    }

    .points {
        font-family: 'Overpass';
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 28px;
        letter-spacing: -0.02em;
        background: var(--color-primary-50);
        border: 1px solid var(--color-primary-700);

    }

    .indicator {
        background: var(--color-error-600);
    }

    .converting {
        max-width: 1180px;
        width: 1180px;
        margin: 0 auto;
        padding: 0;
    }

    .graph-title {
        color: #777;
    }

    @media (max-width: 1366px) {

        .anamnese-panel-outter, .graph-panel-outter {
            width: 100% !important;
            max-width: 100% !important;
        }
    }

    * {
        scrollbar-width: thin;
        scrollbar-color: var(--color-primary-800);
    }

    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 4px;
    }

    *::-webkit-scrollbar-track {
        background: var(--color-shade-0);
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--color-shade-1) !important;
        border-radius: 20px;
        border: 0px;
    }

    .tests-table tr td {
        padding: 6px;
        border: 2px solid #5a5a5a;
    }

</style>