import usePatients from '@/api/usePatients'
import _ from 'lodash'

const { index, show, update, store, destroy,
        startDataConsent, commitDataConsent, deleteDataConsent } = usePatients()

export default {
    namespaced: true,

    state () {
        return {
            patients: {
                data: [],
                meta: {
                    current_page: 1,
                    from: 1,
                    last_page: 1,
                    links: [],
                    path: "",
                    per_page: 0,
                    to: 0,
                    total: 0,
                    table: "",
                    columnNames: [],
                    displayableColumnNames: []
                }
            },
        }
    },

    getters: {
        all (state) {
            return state.patients 
        },

        table (state) {
            return state.patients.meta.table
        }
    },

    actions: {
        async all({commit}, params) {

            let patients = await index(params).catch((err) => {
                return Promise.reject(err)
            })

            commit('SET_PATIENTS', patients)

            return Promise.resolve(patients)
        },

        async storePatient({commit, dispatch}, data) {
           
            let patient = await store(data).catch((err) => {
                return Promise.reject(err)
            })
            //commit('STORE_PATIENT', patient)

            dispatch('all', {
                page: 1,
                order_by: '',
                direction: 'asc'
            })

            return Promise.resolve(patient)
        },

        async updatePatient({commit}, data) {
           
            let id = data.uuid
            console.log(data)

            let patient = await update(id, data).catch((err) => {
                return Promise.reject(err)
            })

            commit('UPDATE_PATIENT', patient)

            return Promise.resolve(patient)

        },

        async deletePatient({commit, dispatch}, data) {
            
            let id = data.uuid
            console.log(data)

            await destroy(id, data).catch((err) => {
                return Promise.reject(err)
            })

            commit('DELETE_PATIENT', id)
            
            dispatch('all', {
                page: 1,
                order_by: '',
                direction: ''
            })
        },

        async reloadPatient({commit, dispatch}, id) {

            let patient = await show(id,).catch((err) => {
                return Promise.reject(err)
            })

            commit('UPDATE_PATIENT', patient.data)

            return Promise.resolve(patient.data)
        },

        async doStartDataConsent({commit, dispatch}, id) {

            let data = await startDataConsent(id).catch((err) => {
                return Promise.reject(err)
            })

            dispatch('reloadPatient', id)

            return Promise.resolve(data)
        },

        async doCommitDataConsent({commit, dispatch}, id) {

            let data = await commitDataConsent(id).catch((err) => {
                return Promise.reject(err)
            })

            dispatch('reloadPatient', id)

            return Promise.resolve(data)
        },

        async doDeleteDataConsent({commit, dispatch}, id) {
            
            let data = await deleteDataConsent(id).catch((err) => {
                return Promise.reject(err)
            })

            dispatch('reloadPatient', id)

            return Promise.resolve(data)
        }



    },

    mutations: {
        SET_PATIENTS (state, patients) {
            state.patients = patients
        },

        STORE_PATIENT (state, patient) {
            console.log("state.patients.data")
            console.log(state.patients.data)

            //state.patients.data.pop() // necessary to fix pagination and keys
            //state.patients.data.unshift(patient)
        },

        UPDATE_PATIENT (state, patient) {
            console.log(patient)

            console.log("state.patients.data")
            console.log(state.patients.data)
            
            _.assign(_.find(state.patients.data, { uuid: patient.uuid }), patient)
        },

        DELETE_PATIENT (state, patient) {
            console.log("state.patients.data")
            console.log(state.patients.data)
            //todo
            //state.patients.data.splice(_.findIndex(state.patients.data, { uuid: patient.uuid }), 1)
        }
    }
}