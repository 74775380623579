<template>
    <div class="flex flex-1 surface-4 p-3">
        <div class="flex-1 p-3 surface-2 rounded'">
            <h1 class="heading-3-medium text-1">Anleitung</h1>

            <div class="h-1 hr-divider mb-4"></div>
            <div class="bg-white rounded px-3 pt-10 pb-5">

                
                <div v-if="documents" class="documentation mb-4 p-2 rounded-lg w-1/2">
                    <div class="mx-auto flex items-center justify-center"><IconPdfBig /></div>
                    
                    <h3 class="py-4">Dokumente</h3>
                    <p>
                      Hier können Sie sich einen Überblick über unsere komplette Dokumentation verschaffen
                    </p>
            
                    <div class="documentation-inner mt-4 p-2 rounded scroll-auto text-center flex-1 w-3/5 mx-auto">
                      <ul class="text-white max-h-96 overflow-y-auto pr-1">
                        <li v-for="document in documents"><a :href="document.url" target="_blank" class="mb-2 flex items-center justify-center brand doc-link-btn bg-white rounded w-full"><span class="overflow-hidden truncate px-2">{{ document.name }}</span></a></li>
                      </ul>
                    </div>
                
                </div>

            </div>
        </div>
    </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { ref, toRaw, reactive, computed, onMounted, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'


const store = useStore()
const router = useRouter()
const route = useRoute()

const patientId = route.params.patientId

const treatmentId = route.params.treatmentId

let documents = computed(() => store.getters['documents/all'])

onMounted(async () => {
    await store.dispatch('documents/all')
})

</script>
<style scoped>

* {
    scrollbar-width: thin;
    scrollbar-color: var(--color-primary-800);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 4px;
}

*::-webkit-scrollbar-track {
    background: var(--color-primary-600);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--color-primary-700) !important;
    border-radius: 20px;
    border: 0px;
}

.documentation {
  background: var(--color-shade-3);
}

.documentation-inner {
  background: var(--color-shade-2);
}
.documentation h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  color: var(--color-shade-1);
}

.documentation p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: var(--color-shade-1);
}
a.disabled {
  pointer-events: none;
}
</style>