<template>
    <div :class="{ 'flex-1 surface-4 p-3': !compact }" class="flex">
        <div :class="{ 'surface-2 rounded': !compact }" class="flex-1 flex flex-col rounded p-3">
            
            <h1 v-if="!compact" class="heading-3-medium text-1">Prüfung Strabismus *</h1>
            <h1 v-else class="heading-4-medium text-8">Prüfung Strabismus *</h1>

            <div class="h-1 hr-divider mb-4"></div>
            
            <div v-if="questions" class="flex-1 flex flex-col surface-1 rounded">
                <div :class="{ 'test-wrapper': !compact }" class="rounded flex-1">
                    <template v-for="question in questions" :key="question.uuid">
                        <div :class="{ 'mt-6': question.question_index > 1 }" class="test-item rounded-lg">
                            <h4 class="subheading-bold text-1">{{ question.question }}</h4>
                            <div v-if="question.possible_answers.length" class="mt-3 -ml-3">
                                <template v-for="(answer, answer_index) in question.possible_answers" :key="answer">
                                    <label @click.prevent="handleQuestionAnswer(question.uuid, answer)" :class="{ 'active': answer == question.answer }" class="button-primary --active button-sm ml-3 px-5 py-2 radius-sm">
                                        {{ answer }}
                                    </label>   
                                </template>       
                            </div>

                        </div>
                        <div v-if="question.possible_answers.length" class="mt-2 -ml-3">
                            <template v-for="(answer, answer_index) in question.possible_answers" :key="answer">
                                <div class="ml-3" 
                                    v-if="answer == question.answer 
                                        && question.possible_answer_info_buttons.length > 0 
                                        && question.possible_answer_info_buttons[answer_index]" 
                                        :trigger-type="hover" 
                                        position="right" 
                                        :opened="true">
                                
                                    <div class="text-left text-7 text-caption-upper">{{ question.possible_answer_info_buttons[answer_index] }}</div>
                                </div>   
                            </template>       
                        </div>

                    </template>

                </div>
                <div v-show="!compact" class="mt-4 p-3">
                    <AppWizard step="2" :disabled="isWizardDisabled" @switched="handleStepSwitch" />
                </div>
      
            </div>
        </div>
    </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { ref, toRaw, reactive, computed, onMounted, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import AppTooltip from '@/views/components/common/AppTooltip.vue'
import AppWizard from '@/views/components/common/AppWizard.vue'

import IconCheckbox from '@/views/components/icons/IconCheckbox.vue'
import IconCheckboxChecked from '@/views/components/icons/IconCheckboxChecked.vue'
import IconInfo from '@/views/components/icons/IconInfo.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()

defineProps({
  compact: {
    type: Boolean,
    required: false,
    default: false
  }
})

const showCustomAdditionalAnswer = ref(false)

const customAdditionalAnswer = ref('')

const patientId = route.params.patientId

const treatmentId = route.params.treatmentId

let answerData = reactive({
    questionId: '',
    answer: '',
    additional_answer: '',
    custom_additional_answer: ''
})

let questions = computed(() => store.getters['strabismusquestions/all'])

const group = 'strabismus'

onMounted(async () => {
    console.log(treatmentId)
    console.log('strabismus')
    console.log(group)
    await store.dispatch('strabismusquestions/all', { patientId, treatmentId, group })

   console.log(questions.value)
   console.log(isWizardDisabled.value)
})

let isWizardDisabled = computed(function() {
    let questions = store.getters['strabismusquestions/all']
    let forbidden = questions.filter((q) => q.forbidden_answers.indexOf(q.answer) > -1)

    let answeredWithValidAnswer = questions.filter((q) => q.answer == 'Nein')

    return forbidden.length > 0 || answeredWithValidAnswer.length != questions.length
})

let handleStepSwitch = async function({ routeName }) {

    await store.dispatch('strabismusquestions/clearQuestions')

    router.push({ 
        name: routeName,
        params: {
            ...route.params,
            patientId,
            treatmentId
        }
    })
}

let handleQuestionAnswer = async function(questionId, answer) {
    console.log('question ' + questionId + '   - ' + answer)

    let answerResp = await store.dispatch('strabismusquestions/updateQuestion',{
        patientId,
        treatmentId,
        questionId,
        data: {
            answer,
            additional_answer: 0
        }
    })

    console.log(answerResp)
    
}

let handleQuestionAdditionalAnswer = async function(questionId, answer, additionalAnswerIndex, showAdditionalCustomAnswer) {
    console.log('question ' + questionId + '   - ' + additionalAnswerIndex)

    if(showAdditionalCustomAnswer) {
        showCustomAdditionalAnswer.value = !showCustomAdditionalAnswer.value
    } else {
        showCustomAdditionalAnswer.value = false
    }

    let answerResp = await store.dispatch('strabismusquestions/updateQuestion',{
        patientId,
        treatmentId,
        questionId,
        data: {
            answer,
            additional_answer: additionalAnswerIndex
        }
    })

    console.log(answerResp)
    
}

let handleQuestionCustomAdditionalAnswerEdit = function(questionId, answer, additionalAnswerIndex) {

    console.log(questionId)
    console.log(additionalAnswerIndex)


    answerData.questionId = questionId
    answerData.answer = answer
    answerData.additional_answer = additionalAnswerIndex
}
let handleQuestionCustomAdditionalAnswerBlur = async function(event) {
    console.log("blur")
    answerData.custom_additional_answer = event.target.value

    let answerResp = await store.dispatch('strabismusquestions/updateQuestion', {
        patientId,
        treatmentId,
        questionId: answerData.questionId,
        data: {
            answer: answerData.answer,
            additional_answer: answerData.additional_answer,
            custom_additional_answer: answerData.custom_additional_answer
        }
    })

    console.log(answerResp) 
}

let handleQuestionCustomAdditionalAnswer = async function(event) {
    console.log(event)
    if(event.key == 'Enter') {

        answerData.custom_additional_answer = event.target.value

        let answerResp = await store.dispatch('strabismusquestions/updateQuestion', {
            patientId,
            treatmentId,
            questionId: answerData.questionId,
            data: {
                answer: answerData.answer,
                additional_answer: answerData.additional_answer,
                custom_additional_answer: answerData.custom_additional_answer
            }
        })

        console.log(answerResp)   
    } else if(event.key == 'Escape') {

    } else {
        answerData.custom_additional_answer = event.target.value

    }

}


</script>