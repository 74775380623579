<template>
    <div class="flex w-full surface-4 p-3">
        <div class="flex-1 surface-2 rounded-sm">
            <div v-if="false" class="panel panel-default animated fadeIn">
                <div class="panel-heading">
                    <h3>
                        {{ state.data.table }}
                        <button class="main-btn pull-right" data-toggle="modal" data-target="#createUserModal">Create user</button>
                    </h3>
                </div>

                <div class="panel-body">
                    <div class="well widget-well">

                        <form action="#" @submit.prevent="handleSearch()">
                            <label for="search">Search</label>
                            <div class="row row-fluid">
                                <div class="form-group col-md-3">
                                    <select class="form-control input-lg" v-model="state.search.column">
                                        <option v-for="column in state.data.columnNames" :value="column">
                                            {{ column }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <select class="form-control input-lg" v-model="state.search.operator">
                                        <option value="like">Contains</option>
                                        <option value="eq">Equals</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="input-group">
                                        <input type="text" id="search" v-model="state.search.value" class="form-control input-lg" placeholder="Search">

                                        <span class="input-group-btn">
                                            <button class="btn btn-default btn-lg" type="submit">Search</button>
                                            <button v-if="state.search.value" @click="clearSearch()" class="btn btn-default btn-lg">
                                                <i class="fa fa-times" aria-hidden="true"></i>
                                            </button>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </form>
                        <hr>
                        <div class="row">

                            <div class="form-group col-md-10">

                                    <label for="filter">
                                        {{ state.quickSearchQuery.length ? 'Clear current quick ' : 'Quick ' }}search results:
                                        <a href="#" v-if="state.quickSearchQuery.length" @click.prevent="state.quickSearchQuery = ''"> clear </a>
                                    </label>
                                    <input type="text" id="filter" class="form-control input-sm" v-model="state.quickSearchQuery" placeholder="Quick search">

                            </div>
                            <div class="form-group col-md-2">
                                <label for="limit">Display records</label>
                                <select id="limit" class="form-control input-sm" name="limit" v-model="state.limit" @change="handleLimitChange">
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="1000">1000</option>
                                </select>
                            </div>
                        </div>

                    </div>


                </div>
    {{  selectedUserIds  }}



                <!-- Modal -->
                <div class="modal fade" id="createUserModal" tabindex="-1" role="dialog"
                    aria-labelledby="myModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <!-- Modal Header -->
                            <div class="modal-header">
                                <button type="button" class="close"
                                        data-dismiss="modal">
                                    <span aria-hidden="true">&times;</span>
                                    <span class="sr-only">Close</span>
                                </button>
                                <h4 class="modal-title" id="myModalLabel">
                                    Create user
                                </h4>
                            </div>
                            <form role="form" @submit.prevent="createUser()">
                                <!-- Modal Body -->
                                <div class="modal-body">
                                        <div class="form-group">
                                            <label for="name">Name</label>
                                            <input v-model="state.forms.creating.name" type="text" class="form-control"
                                                id="name" placeholder="Enter name"/>
                                        </div>
                                        <div class="form-group">
                                            <label for="email">Email address</label>
                                            <input v-model="state.forms.creating.email" type="email" class="form-control"
                                                id="email" placeholder="Enter email"/>
                                        </div>
                                        <div class="form-group">
                                            <label for="password">Password</label>
                                            <input v-model="state.forms.creating.password" type="password" class="form-control"
                                                id="password" placeholder="Password"/>
                                        </div>
                                        <div class="checkbox">
                                            <label>
                                                <input type="checkbox"/> Check me out
                                            </label>
                                        </div>


                                </div>

                                <!-- Modal Footer -->
                                <div class="modal-footer">
                                    <button type="button" class="main-btn main-btn-danger"
                                            data-dismiss="modal">
                                        Cancel
                                    </button>
                                    <button type="submit" class="main-btn">
                                        Create user
                                    </button>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>


            </div>

            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="heading-5-medium p-6">{{ title }}</div>
                    <div class="container-fluid panel-container">
                        <div class="col-xs-3 text-left">
                            <div class="btn-group" v-if="state.selected.length">
                                <a href="#" data-toggle="dropdown">{{ state.selected.length }} selected <span class="caret"></span></a>
                                <ul class="dropdown-menu">
                                    <li><a href="#" @click.prevent="deleteRecord(state.selected)">Delete</a></li>
                                    <li v-if="state.selected.length"><a href="#" @click.prevent="clearSelected()">Clear</a></li>

                                </ul>

                            </div>
                        </div>
            
                    </div>

                </div>
                <AppDataTable resource="users" 
                    @onSelected="onSelectedChange"
                    :selectable="selectable" 
                    :selectableFn="function(user) { return user.role.toLowerCase() === 'user' }" 
                    clickable="true"
                    ref="appDataTableRef">
                    <template v-slot:th>  
                        <th class="heading-6-bold text-4 px-6 pt-2">Rechte</th>
                        <th class="heading-6-bold text-4 px-6 pt-2">Status</th>
                        <th>&nbsp;</th>
                    </template>    
            
                    <template v-slot:td="{ record }">
                        <td>
                            <AppDropdown
                                :key="record.uuid"
                                :options="[ 
                                    { name : 'User', value : 'user' },
                                    { name : 'Admin', value : 'admin' }
                                ]"
                                :default="record.role == 'admin' ? { name : 'Admin', value : 'admin' } : { name : 'User', value : 'user' }"
                                :tabindex="tabindex++"
                                :disabled="authUser.role == 'user' || (authUser.role == 'admin' && authUser.uuid == record.uuid)"
                                class="select" 
                                @click.stop=""
                                @input="handleDropdownInput($event, record)" 
                            />
                        </td>
                        <td>
                            <template v-if="record.status == 1">
                                <button @click.stop="showToggleUserActivationConfirmation(record)" :disabled="authUser.role == 'user' || (authUser.role == 'admin' && authUser.uuid == record.uuid)" class="button-sm button-success w-32 px-5 py-2" type="submit">
                                    Aktiviert
                                </button>
                            </template>
                            <template v-else>
                                <button @click.stop="showToggleUserActivationConfirmation(record)" :disabled="authUser.role == 'user' || (authUser.role == 'admin' && authUser.uuid == record.uuid)" class="button-sm button-danger w-32 px-5 py-2" type="submit">
                                    Deaktiviert
                                </button>
                            </template>
                        </td>
                        <td>
                            <div class="btn-group">
                                <button @click.stop="showPasswordResetConfirmation(record)" class="button-sm button-warning px-5 py-2" type="submit">
                                    Passwort zurücksetzen
                                </button>
                            </div>
                        </td>
                    </template> 

                    <template v-slot:bottom-start>
                        <div class="flex flex-wrap items-center">
                        <button v-if="authUser.role == 'admin'" @click="showCreateUserModal()" class="button-primary inline-flex flex-shrink-0 items-center px-3 mb-2 mr-10">
                            <span class="inline-flex items-center justify-center rounded-full font-bold mr-2">+</span> Benutzer einfügen
                        </button>

                        <div class="flex flex-wrap items-center">
                            <button v-if="selectedUserIds.length" @click="showDeleteUserConfirmationModal()" class="button-primary inline-flex flex-shrink-0 items-center px-3 mb-2">
                                <span class="bg-white inline-flex items-center justify-center text-1 text-xs font-bold w-5 h-5 mr-2">{{ selectedUserIds.length }}</span> Benutzer löschen
                            </button>
                            <button v-if="selectedUserIds.length"  @click="cancelDeleteSelectedUsers()" class="button-primary-outlined bg-white inline-flex items-center px-3 ml-3 mb-2">
                                Abbrechen
                            </button>
                        </div>
                    </div>
                    </template>
                </AppDataTable>
            </div>

        </div>
    </div>

    <AppModal v-show="isCreateUserModalVisible" @close="closeCreateUserModal(); createUserStep=1" class="modal-dimmer xl:w-3/6">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body>
            <div v-if="createUserStep == 1" class="flex flex-col w-1/2 mx-auto">
                <h1 class="heading-1-bold-s pt-10 text-center">BENUTZER EINFÜGEN</h1>
                <form class="pt-5" v-on:submit.prevent="createUser">
                    <div :class="{ 'has-error': errors.name[0] }" class="mb-7">
                        <label class="input-label-lg text-1 mb-2 uppercase" for="name">
                            Vorname und Nachname
                        </label>
                        <input v-model="form.name" @focus="reset('name')" class="input border rounded w-full" id="name" type="text" placeholder="Vorname und Nachname">
                        <span class="feedback" v-if="errors.name[0]">{{ errors.name[0] }}</span>
                    </div>
                    <div :class="{ 'has-error': errors.email[0] }" class="mb-7">
                        <label class="input-label-lg text-1 mb-2 uppercase" for="email">
                        E-mail-adresse
                        </label>
                        <input v-model="form.email" @focus="reset('email')" class="input border rounded w-full" id="email" type="text" placeholder="E-Mail-Adresse">
                        <span class="feedback" v-if="errors.email[0]">{{ errors.email[0] }}</span>
                    </div>
                    <div :class="{ 'has-error': errors.email[0] }" class="mb-7">
                        <label class="input-label-lg text-1 mb-2 uppercase" for="role">
                        Rechte
                        </label>
                        <select v-model="form.role" @focus="reset('role')" class="input border rounded w-full" id="role" type="text">
                            <option value="select" disabled>Rechte</option>
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                        </select>
                        <span class="feedback" v-if="errors.role[0]">{{ errors.role[0] }}</span>
                    </div>

                    <div class="flex items-center justify-between">
                        <button class="button-primary w-full" type="submit">
                            Benutzer einfügen
                        </button>
                    </div>
    
          
                </form>
            </div>
            <div v-if="createUserStep == 2" class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10">BENUTZER EINFÜGEN</h1>
                <p class="w-96 mx-auto mt-7 leading-4">
                    Registrationslink wurde versendet! 
                </p>
                <p class="w-96 mx-auto mt-7 leading-4">
                    Benutzer kann via Registrierungslink sich anmelden und die Applikation verwenden
                </p>
                <div class="flex items-center justify-between mt-7">
                    <button @click="closeCreateUserModal()" class="button-primary w-96 mx-auto" type="submit">
                        Schliesen
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppModal>


    <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="activationToggleConfirmation">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body>
            <div class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10">BENUTZER {{ currentUser.status == 1 ? 'DEAKTIVIEREN' : 'AKTIVIEREN'}}</h1>
                <p class="w-96 mx-auto mt-7 leading-4">
                    <template v-if="currentUser.status == 1">
                        Bitte bestätigen Sie, dass Sie diesen Benutzer deaktivieren möchten. Die Deaktivierung des Benutzers würde dem Benutzer den Zugang zu dieser Anwendung und Daten sperren
                    </template>
                    <template v-else>
                        Bitte bestätigen Sie, dass Sie diesen Benutzer aktivieren möchten. Die Aktivierung des Benutzers würde dem Benutzer den Zugang zu dieser Anwendung und Daten ermöglichen
                    </template>
                </p>
                <div class="flex items-center justify-between mt-7">
                    <button @click="toggleUserActivation()" class="button-primary w-96 mx-auto">
                        {{ currentUser.status == 1 ? 'Deaktivierung' : 'Aktivierung' }} bestätigen
                    </button>
                </div>
                <div class="flex items-center justify-between mt-7">
                    <button @click="closeToggleUserActivationConfirmation()" class="button-primary-outlined w-96 mx-auto">
                        Abbrechen
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>

    <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="rightsChangeConfirmationModal">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">
            <div class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10">ROLLE ÄNDERN</h1>
                <p class="w-96 mx-auto mt-7 leading-4">
                    Sind Sie sicher, dass Sie die Rolle dieses Benutzers ändern wollen? 
                </p>
                <div class="flex items-center justify-between mt-7">
                    <button @click="changeUserRights()" class="button-primary w-96 mx-auto">
                        Ja
                    </button>
                </div>
                <div class="flex items-center justify-between mt-7">
                    <button @click="closeChangeUserRightsConfirmation()" class="button-primary-outlined w-96 mx-auto">
                        Abbrechen
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>

    <AppConfirmModal class="modal-dimmer xl:w-3/6" ref="passwordResetConfirmationModal">
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">
            <div class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10">Passwort zurücksetzen?</h1>
                <p class="w-96 mx-auto mt-7 leading-4">
                    Sind Sie sicher, dass Sie die Rolle dieses Benutzers ändern wollen? 
                </p>
                <div class="flex items-center justify-between mt-7">
                    <button @click="changePassword()" class="button-primary w-96 mx-auto">
                        Ja
                    </button>
                </div>
                <div class="flex items-center justify-between mt-7">
                    <button @click="closePasswordResetConfirmation()" class="button-primary-outlined w-96 mx-auto">
                        Abbrechen
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppConfirmModal>


    <AppModal class="modal-dimmer xl:w-3/6" v-show="isDeleteUserConfirmationModalVisible" @close="closeDeleteUserConfirmationModal()" >
        <template v-slot:header>
            <template></template>
        </template>
        <template v-slot:body scope="props">
            <div class="flex flex-col text-center h-96">
                <h1 class="heading-1-bold-s pt-10">BENUTZER LÖSCHEN</h1>
                <p class="w-96 mx-auto mt-7 leading-4">
                    Sind Sie sicher, dass Sie ausgewählte Benutzer löschen?
                </p>
                <div class="flex items-center justify-between mt-7">
                    <button @click="deleteSelectedUsers()" class="button-primary w-96 mx-auto">
                        Ja
                    </button>
                </div>
                <div class="flex items-center justify-between mt-7">
                    <button @click="closeDeleteUserConfirmationModal()" class="button-primary-outlined w-96 mx-auto">
                        Abbrechen
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <template></template>
        </template>
    </AppModal>
</template>

<script setup>

    import { reactive, ref, toRaw, computed, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter, useRoute } from 'vue-router'
    import { useToast } from 'vue-toast-notification'
    import 'vue-toast-notification/dist/theme-sugar.css'

    import _ from 'lodash'
    import axios from 'axios'
    
    import AppDataTable from '@/views/components/common/AppDataTable.vue'
    import AppDropdown from '@/views/components/common/AppDropdown.vue'
    import AppModal from '@/views/components/common/AppModal.vue'
    import AppConfirmModal from '@/views/components/common/AppConfirmModal.vue'

    import AppPagination from '@/views/components/common/AppPagination.vue'
    import IconChevron from '@/views/components/icons/IconChevron.vue'

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const toast = useToast()

    const appDataTableRef = ref(null)

    let authUser = computed(() => store.getters['auth/user'])

    let title = computed(() => store.getters['users/table'])
    let selectable = true
    let isCreateUserModalVisible = ref(false)
    let createUserStep = ref(1)
    
    let isDeleteUserConfirmationModalVisible = ref(false)


    let tabindex = 0
    
    const DEFAULT_PER_PAGE = 4

    const state = reactive({
        selectAllChecked: false,
        selected: [],
        data: [],
        sort: {
            key: '',
            order: 'asc'
        },
        search: {
            value: '',
            operator: 'like',
            column: 'name'
        },
        quickSearchQuery: '',
        per_page: route.query.per_page ? route.query.per_page : DEFAULT_PER_PAGE,
        page: route.query.page ? route.query.page : 1,
        forms: {
            creating: {
                name: '',
                email: '',
                password: ''
            }
        }
    })

    const form = reactive({
        name: '',
        email: '',
        role: 'select'
    })

    let errors = reactive({
        name: [],
        email: [],
        role: [], 
    })

    const reset = (field) => {
        console.log(field)
        errors[field] = [];
    } 

    const activationToggleConfirmation = ref()
    const rightsChangeConfirmationModal = ref()
    const passwordResetConfirmationModal = ref()
    const deleteUsersConfirmationModal = ref()

    let currentUser = ref()
    let selectedUserIds = ref([])

    onMounted(() => {
        console.log(router)
        console.log(authUser.value.data)
    })

    let onSelectedChange = function({ selected }) {
        console.log('onSelectedChange ', selected)
        selectedUserIds.value = selected
    }

    let showToggleUserActivationConfirmation = async function(user) {

        currentUser.value = user
        
        await activationToggleConfirmation.value.show().catch((err) => {
            //console.log(err)
        })

        activationToggleConfirmation.value.close()
    }

    let closeToggleUserActivationConfirmation = function() {
        currentUser.value = null
        activationToggleConfirmation.value.close()
    }

    let toggleUserActivation = async function() {

        let user = toRaw(currentUser.value)

        user.status = (user.status == 0 ? 1 : 0)

        await store.dispatch('users/updateUser', user)

        activationToggleConfirmation.value.close()
    }

    let changeUserRightsCtx

    let closeChangeUserRightsConfirmation = function() {
        changeUserRightsCtx.notConfirmed()
        rightsChangeConfirmationModal.value.close()
    }

    let changeUserRights = async function() {

        let user = toRaw(currentUser.value)

        user.role = changeUserRightsCtx.value

        await store.dispatch('users/updateUser', user)

        changeUserRightsCtx.confirmed()

        rightsChangeConfirmationModal.value.close()
    }


    let showPasswordResetConfirmation = async function(user) {
        currentUser.value = user

        await passwordResetConfirmationModal.value.show().catch((err) => {
            //console.log(err)
        })
    }

    let closePasswordResetConfirmation = function() {
        passwordResetConfirmationModal.value.close()
    }

    let changePassword = async function() {
        let payload = { email: currentUser.value.email }

        console.log(payload)

        await store.dispatch('auth/requestPasswordReset', payload)

        closePasswordResetConfirmation()
    }

    const showCreateUserModal = () => {
        createUserStep.value = 1

        document.body.classList.add('overflow-hidden')
        isCreateUserModalVisible.value = true
    }

    const closeCreateUserModal = () => {
        document.body.classList.remove('overflow-hidden')
        isCreateUserModalVisible.value = false
    }

    const showDeleteUserConfirmationModal = () => {
        document.body.classList.add('overflow-hidden')
        isDeleteUserConfirmationModalVisible.value = true
    }

    const closeDeleteUserConfirmationModal = () => {
        document.body.classList.remove('overflow-hidden')
        isDeleteUserConfirmationModalVisible.value = false
    }

    let createUser = async function() {
        let payload = toRaw(form)

        console.log(payload)

        let resp = await store.dispatch('users/storeUser', payload).catch((err) => {
            Object.assign(errors, err.errors) 
        })

        if(resp) {
            await store.dispatch('users/all', { page: 1, order_by: '', direction: 'asc', per_page: state.per_page }).catch((err) => {})
            createUserStep.value = 2
        }
    }

    let deleteSelectedUsers = async function() {

        if(selectedUserIds.value.length) {
            let uuids = toRaw(selectedUserIds.value)

            let resp = await store.dispatch('users/bulkDelete', uuids)

            selectedUserIds.value = []

            toast.default(resp.message, { position: 'top-right' })

            await store.dispatch('users/all', { page: 1, per_page: state.per_page}).catch((err) => {})

        }

        closeDeleteUserConfirmationModal()
    }
    
   
   let cancelDeleteSelectedUsers = function() {
    console.log(appDataTableRef.value);
    appDataTableRef.value.clearSelected()
   }
   
   
    const handleDropdownInput = async (event, user) => {

        currentUser.value = user

        changeUserRightsCtx = event

        await rightsChangeConfirmationModal.value.show().catch((err) => {})

    }

</script>
<style scoped>
    .sortable {
        cursor: pointer;
    }

    .sort-icon { transition: transform .1s ease-in; }

    .arrow--desc {
        transform: scaleY(-1);
    }

    .table-responsive {
        overflow-y: visible !important;
    }

    .widget-well {
        margin-bottom: 0;
        background: #fff;
        box-shadow: none;
    }

    .checkbox-lg {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    th  {
        min-width: 55px;
    }

    .button-sm {
        line-height: 14px;
    }

    button[disabled] { opacity: 0.6 !important; }

</style>