import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import options from './options.js'
import 'animate.css';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

axios.defaults.baseURL = options.baseUrl
//axios.defaults.baseURL = 'https://btso.talismanit.com'
//axios.defaults.baseURL = 'https://btsotest.io.technik.fhnw.ch'
axios.defaults.withCredentials = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'


if(screen && screen.orientation && screen.orientation.lock) {
    screen.orientation.lock("landscape")
        .then(function() {
            console.log('Locked')
        })
        .catch(function(error) {
            console.error(error);
        })
}

/*
const query = matchMedia("screen and (orientation:portrait)");
query.onchange = e => {

  if(!screen.orientation.lock) return
  console.log( 'orientation change', query.matches ? 'portrait' : 'landscape' );
  // though beware square will be marked as landscape here,
  // if you want to handle this special case
  // create an other mediaquery (orientation:landscape) 
  console.log("The orientation of the screen is: " + screen.orientation.type)
  screen.orientation.type.startsWith("portrait") && screen.orientation.lock("landscape-primary")

}
screen.orientation.lock && screen.orientation.type.startsWith("portrait") && screen.orientation.lock("landscape-primary")
*/
const app = createApp(App)

app.use(ToastPlugin)

store.dispatch('auth/user')
    .finally(() => {
        app.use(router)
        app.use(store)

        app.mount('#app')
        
    })
    .catch((err) => {
        console.log('login error')
    })

