import useTreatments from '@/api/useTreatments'
import _ from 'lodash'

const { index, show, update, store, destroy, finalize } = useTreatments()

export default {
    namespaced: true,

    state () {
        return {
            treatments: {
                data: [],
                meta: {
                    current_page: 1,
                    from: 1,
                    last_page: 1,
                    links: [],
                    path: "",
                    per_page: 0,
                    to: 0,
                    total: 0,
                    table: "",
                    columnNames: [],
                    displayableColumnNames: []
                }
            },

            treatment: {
                data: null
            }
        }
    },

    getters: {
        all (state) {
            return state.treatments 
        },

        treatment (state) {
            return state.treatment 
        },
    },

    actions: {
        async all({ commit }, patientId) {

            let treatments = await index(patientId).catch((err) => {
                return Promise.reject(err)
            })

            commit('SET_TREATMENTS', treatments)
        },

        async treatment({ commit }, { patientId, treatmentId }) {

            let treatment = await show(patientId, treatmentId).catch((err) => {
                return Promise.reject(err)
            })

            commit('SET_TREATMENT', treatment)
        },

        async storeTreatment({ commit }, { patientId, data }) {
            console.log(data)
            
            let treatment = await store(patientId, data).catch((err) => {
                return Promise.reject(err)
            })

            commit('STORE_TREATMENT', treatment)

            return Promise.resolve(treatment)
        },

        async updateTreatment({ commit }, { patientId, data }) {
            let treatmentId = data.uuid
            console.log(data)

            let treatment = await update(patientId, treatmentId, data).catch((err) => {
                return Promise.reject(err)
            })

            commit('UPDATE_TREATMENT', treatment)
        },

        async finalizeTreatment({ commit }, { patientId, treatmentId, data }) {
   
            let treatment = await finalize(patientId, treatmentId, data).catch((err) => {
                return Promise.reject(err)
            })

            return Promise.resolve(treatment)
        },

        async deleteTreatment({commit, dispatch}, { patientId, treatmentId }) {

            console.log(patientId, treatmentId)

            await destroy(patientId, treatmentId).catch((err) => {
                return Promise.reject(err)
            })

            commit('DELETE_TREATMENT', treatmentId)
            
            dispatch('all', patientId)
        },

        async clearTreatments({ commit }) {
            commit('CLEAR_TREATMENTS')
        }
    },

    mutations: {
        SET_TREATMENTS (state, treatments) {
            state.treatments = treatments
        },

        SET_TREATMENT (state, treatment) {
            state.treatment = treatment
        },

        STORE_TREATMENT (state, treatment) {
            console.log("state.treatments.data")
            console.log(state.treatments.data)

            state.treatments.data.push(treatment)
        },

        UPDATE_TREATMENT (state, treatment) {
            console.log("UPDATE_TREATMENT")

            console.log("state.treatments.data")
            console.log(state.treatments.data)
            
            _.assign(_.find(state.treatments.data, { uuid: treatment.uuid }), treatment)
        },


        DELETE_TREATMENT (state, treatment) {
            console.log("state.treatments.data")
            console.log(state.treatments.data)
        },

        CLEAR_TREATMENTS (state) {
            state.treatments = {
                data: [],
                meta: {
                    current_page: 1,
                    from: 1,
                    last_page: 1,
                    links: [],
                    path: "",
                    per_page: 0,
                    to: 0,
                    total: 0,
                    table: "",
                    columnNames: [],
                    displayableColumnNames: []
                }
            }
        },
    }
}