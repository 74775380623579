import useQuestions from '@/api/useQuestions'
import _ from 'lodash'

const { index, update, skip } = useQuestions()

export default {
    namespaced: true,

    state () {
        return {
            questions: {
                data: []
            },
        }
    },

    getters: {
        all(state) {
            return state.questions.data 
        },
    },

    actions: {
        async all({ commit }, { patientId, treatmentId, group } ) {

            let questions = await index(patientId, treatmentId, group).catch((err) => {
                return Promise.reject(err)
            })

            commit('SET_QUESTIONS', questions.data)
        },

        async updateQuestion({ commit }, { patientId, treatmentId, questionId, data }) {
            console.log(data)

            let question = await update(patientId, treatmentId, questionId, data).catch((err) => {
                return Promise.reject(err)
            })

            commit('UPDATE_QUESTION', question)
        },

        async clearQuestions({ commit }) {
            commit('CLEAR_QUESTIONS')
        },

        async skipQuestions({ commit }, { patientId, treatmentId, group}) {
            await skip(patientId, treatmentId, group).catch((err) => {
                return Promise.reject(err)
            })
        }
    },

    mutations: {
        SET_QUESTIONS(state, questions) {
            state.questions.data = questions
        },

        UPDATE_QUESTION(state, question) {          
            _.assign(_.find(state.questions.data, { uuid: question.uuid }), question)
        },

        CLEAR_QUESTIONS (state) {
            state.questions = {
                data: [],
            }
        },
    }
}