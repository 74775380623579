import axios from 'axios'

export default function useAuth() {

    const login = async(credentials) => {
        await axios.get('/sanctum/csrf-cookie')
        
        let loginResponse = await axios.post('/api/auth/login', credentials).catch((err) => {
            return Promise.reject(err.response.data)
        })
        
        let data = loginResponse.data
     
        if (data.two_auth_required) {
            return Promise.resolve(data)
        }

        let userResponse = await axios.get('/api/auth/user').catch((err) => {
            return Promise.reject(err.response.data)
        })

        let user = userResponse.data.data

        return Promise.resolve(user)
    }

    const login2FA = async(credentials) => {

        await axios.get('/sanctum/csrf-cookie')
        
        await axios.post('/api/auth/email-code', credentials).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let response = await axios.get('/api/auth/user').catch((err) => {
            return Promise.reject(err.response.data)
        })

        let user = response.data.data

        return Promise.resolve(user)
    }

    const logout = async() => {

        let response = await axios.post('/api/auth/logout').catch((err) => {
            return Promise.reject(err)
        })

        let data = response.data.data

        return Promise.resolve(data)
    }

    const fetchUser = async() => {

        let response = await axios.get('/api/auth/user').catch((err) => {
            return Promise.reject(err)
        })

        let user = response.data.data

        return Promise.resolve(user)
    }

    const registerUser = async(payload) => {
        await axios.get('/sanctum/csrf-cookie')

        let response = await axios.post('/api/auth/register', payload).catch((err) => {
            return Promise.reject(err.response)
        })

        let user = response.data.data

        return Promise.resolve(user)
    }

    const resetPassword = async(payload) => {

        let response = await axios.post('/forgot-password', payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let user = response.data.data

        return Promise.resolve(user)
    }

    //Set new password through sent password reset link!
    const storeNewPassword = async(payload) => {
        console.log(payload)

        let response = await axios.post('/reset-password', payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let data = response.data

        return Promise.resolve(data)
    }

    const changePassword = async(payload) => {
        let response = await axios.post(`/api/auth/password-change`, payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let data = response
    
        return Promise.resolve(data)
    }

    const saveViewSettings = async(payload) => {
        let response = await axios.post(`/api/auth/view-settings`, payload).catch((err) => {
            return Promise.reject(err.response.data)
        })

        let data = response
    
        return Promise.resolve(data)
    }

    return {
        login,
        login2FA,
        logout,
        fetchUser,
        registerUser,
        resetPassword,
        storeNewPassword,
        changePassword,
        saveViewSettings
    }

}